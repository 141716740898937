import { Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid';
import { DataGridCustomStyles, gridRow } from '@styles/shared/SDataGrid';
import { TTransaction } from '@typescript/models/TradeTransaction.model';
import { formatNr, sliceWalletAddress } from '@utils/functions';
import moment from 'moment';
import { memo } from 'react';

const addressCell = (address: string, userWallet: string) => {
  if (address === userWallet) {
    return <Typography>USER</Typography>;
  }
  return (
    <Tooltip placement="top" title={address}>
      <Typography>{sliceWalletAddress(address)}</Typography>
    </Tooltip>
  );
};

const transactionColumns = (
  userWallet: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: (tx: TTransaction) => void,
): GridColumns<TTransaction> => [
  {
    ...gridRow,
    field: 'ID',
    headerName: 'ID',
    valueGetter: ({ row }) => row.id,
  },
  {
    ...gridRow,
    field: 'Project',
    headerName: 'Project',
    valueGetter: ({ row }) => row.project.name,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.project.id}>
        <Typography>{row.project.name}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'From',
    headerName: 'From',
    valueGetter: ({ row }) => row.from.ethAddress,
    renderCell: ({ row }) => addressCell(row.from.ethAddress, userWallet),
  },
  {
    ...gridRow,
    field: 'To',
    headerName: 'To',
    valueGetter: ({ row }) => row.to.ethAddress,
    renderCell: ({ row }) => addressCell(row.to.ethAddress, userWallet),
  },
  {
    ...gridRow,
    field: 'Value',
    headerName: 'Value',
    valueGetter: ({ row }) => row.value,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.value}>
        <Typography>{formatNr(row.value, true, 2)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Hash',
    headerName: 'Hash',
    valueGetter: ({ row }) => row.hash,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.hash}>
        <Typography>{sliceWalletAddress(row.hash)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Type',
    headerName: 'Type',
    valueGetter: ({ row }) => row.tradeType,
  },
  {
    ...gridRow,
    field: 'Created At',
    headerName: 'Created At',
    valueGetter: ({ row }) => moment(row.createdAt).format('YYYY-MM-DD HH:mm'),
  },
  // {
  //   ...gridRow,
  //   field: 'Action',
  //   headerName: 'Action',
  //   renderCell: ({ row }) => (
  //     <ButtonGradientDark onClick={() => action(row)}>
  //       Action
  //     </ButtonGradientDark>
  //   ),
  // },
];

interface Props {
  action: (tx: TTransaction) => void;
  rows: TTransaction[];
  userWallet: string;
}

function AdminUserTxTable({ action, rows, userWallet }: Props) {
  return (
    <DataGrid
      sx={DataGridCustomStyles}
      rows={rows}
      columns={transactionColumns(userWallet, action)}
      disableSelectionOnClick
      autoHeight
      pageSize={30}
      rowsPerPageOptions={[30]}
      disableColumnSelector
      disableDensitySelector
      getRowId={(row) => row.id}
      components={{
        Toolbar: GridToolbar,
      }}
    />
  );
}

export default memo(AdminUserTxTable);
