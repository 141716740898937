import { Box, Typography } from '@mui/material';
import { Svgs } from '@utils/svgs';
import { formatNr } from '@utils/functions';
import useUser from '@hooks/useUser';

const CoinImages = {
  USDT: Svgs.USDT,
  USDC: Svgs.USDC,
  DAI: Svgs.DAI,
};

function AccountCoinsComp() {
  const { userCoins } = useUser();

  const coinsData =
    userCoins &&
    userCoins.map(({ balance, name }) => ({
      name,
      balance: formatNr(balance),
      img: CoinImages[name],
    }));

  return (
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        mt: 2,
        borderRadius: '6px',
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          color: 'text.secondary',
        }}
      >
        Coins
      </Typography>
      <Box
        className="Coins"
        sx={{
          display: 'flex',
          gap: { xs: '0.8rem', xsm: '1.5rem' },
          mt: { xs: 2, xsm: 3 },
          alignItems: 'center',
        }}
      >
        {coinsData &&
          coinsData.map((coinData) => (
            <Box className="Coin" key={coinData.name}>
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                <coinData.img />
                <Typography sx={{ opacity: 0.9 }}>{coinData.name}</Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  mt: 0.5,
                }}
              >
                {coinData.balance}
              </Typography>
            </Box>
          ))}
      </Box>
    </Box>
  );
}
export default AccountCoinsComp;
