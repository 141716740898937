import { Box, Button, Typography, useTheme, Divider } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import useUser from '@hooks/useUser';

import * as yup from 'yup';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import { FieldStyled } from '@styles/modal/SModal';
import EnterCodeModal from '@components/account/EnterCodeModal';
import { MetamaskError } from '@utils/errors';
import useNotification from '@hooks/useNotification';
import { toRem } from '@utils/functions';
import { ButtonGradientDark } from '@styles/shared/SButtons';

function AccountUserInfo() {
  const {
    updateUserName,
    currentUser,
    userInformation,
    sendEmail,
    updateEmail,
    updateSocial,
  } = useUser();

  const theme = useTheme();

  const { notifySuccess, notifyError } = useNotification();

  interface UserInfoInitialValues {
    username: string;
    email: string;
    discordName: string;
    telegramLink: string;
  }

  const initialValues: UserInfoInitialValues = {
    username: currentUser?.username || '',
    email: userInformation?.email || '',
    discordName: userInformation?.discordName || '',
    telegramLink: userInformation?.telegramLink || '',
  };

  const validationSchema = () =>
    yup.object().shape({
      email: yup.string().email('Invalid Email.').required('Required.'),
      username: yup.string().required('Required.'),
      discordName: yup.string().required(),
      telegramLink: yup
        .string()
        .matches(/^https:\/\/t\.me\/\S+$/, 'Please add a valid Telegram link')
        .required(),
    });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
  });

  const { values, handleChange, handleBlur, errors, touched, setValues } =
    formik;

  const [loading, setLoading] = useState<boolean>(false);

  const [openCodeModal, setOpenCodeModal] = useState<boolean>(false);

  const socialButtonDisabled =
    Boolean(errors.telegramLink || values.telegramLink === ' ') ||
    Boolean(errors.discordName || values.discordName === ' ');

  const changeUserName = async () => {
    try {
      if (values.username && !errors.username) {
        setLoading(true);
        await updateUserName(values.username);
        notifySuccess('Username changed successfully!');
        setLoading(false);
      }
    } catch (e) {
      const { message, code } = e as MetamaskError;
      if (code === 202) {
        notifyError(message);
      }
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const handleUserUpdate = async () => {
    try {
      if (!userInformation) {
        throw Error('No user information found');
      }

      await updateSocial(
        values.discordName,
        values.telegramLink,
        userInformation,
      );
      notifySuccess('Social changed successfully!');
      setValues((prev) => ({
        ...prev,
        discordName: userInformation?.discordName || '',
      }));
    } catch (e) {
      const { message, code } = e as MetamaskError;
      if (code === 202) {
        notifyError(message);
      }
      throw e;
    }
  };

  const sendEmailCode = async () => {
    if (values.email && !errors.email && userInformation) {
      await sendEmail(values.email, values.username);
      await updateEmail(values.email, userInformation);
      setOpenCodeModal(true);
      notifySuccess('Email sent!');
    }
  };

  useEffect(() => {
    setValues({
      email: userInformation?.email || '',
      username: currentUser?.username || '',
      discordName: userInformation?.discordName || '',
      telegramLink: userInformation?.telegramLink || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, userInformation]);

  if (loading) {
    return (
      <Box sx={{ marginLeft: { xs: 0, md: '4rem' } }}>
        <IsLoadingCom dimension="medium" />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        pb: 2,
        borderRadius: '6px',
        width: '100%',
      }}
    >
      <Divider
        sx={{
          fontSize: toRem(12),
          color: theme.palette.text.secondary,
          position: 'static',
          backgroundColor: theme.palette.secondary.main,
          height: 'unset',
          width: 'unset',
          mb: toRem(32),
        }}
      >
        Account Settings
      </Divider>

      <Box sx={{ display: 'flex', alignItems: 'start' }}>
        <FieldStyled
          fullWidth
          id="username"
          name="username"
          label="Username"
          InputProps={{
            sx: {
              paddingLeft: 0,
              borderRadius: '4px 0 0 4px',
              borderRight: '0!important',
            },
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.username}
          sx={{ flex: 1, minHeight: '90px' }}
          helperText={touched.username ? errors.username : ''}
          error={Boolean(errors.username) && touched.username}
        />
        <Button
          variant="outlined"
          sx={{
            borderRadius: '0 4px 4px 0',
            width: { xs: '125px', xsm: '175px' },
            px: { xs: 0.5, xsm: 2 },
            py: '19.5px',
          }}
          onClick={changeUserName}
          disabled={Boolean(
            errors.username || values.username === currentUser?.username,
          )}
        >
          Change Username
        </Button>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'start' }}>
        <FieldStyled
          fullWidth
          id="email"
          name="email"
          label="Email"
          InputProps={{
            sx: {
              paddingLeft: 0,
              borderRadius: '4px 0 0 4px',
              borderRight: '0!important',
            },
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
          sx={{ flex: 1, minHeight: '90px' }}
          helperText={touched.email ? errors.email : ''}
          error={Boolean(errors.email) && touched.email}
        />
        <Button
          variant="outlined"
          disableElevation
          sx={{
            color: 'white',
            borderRadius: '0 4px 4px 0',
            width: { xs: '125px', xsm: '175px' },
            px: { xs: 0.5, xsm: 2 },
            py: '19.5px',
          }}
          onClick={sendEmailCode}
          disabled={Boolean(
            errors.email || userInformation?.email === values.email,
          )}
        >
          {userInformation?.email ? 'Change Email' : 'Add Email'}
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            color: `${
              userInformation?.isEmailVerified
                ? 'success.main'
                : 'text.secondary'
            }`,
          }}
        >
          {userInformation?.isEmailVerified ? 'Verified' : 'Not Verified'}
        </Typography>
        {userInformation?.email && !userInformation.isEmailVerified && (
          <Button
            sx={{ p: 0, textDecoration: 'underline' }}
            onClick={() => setOpenCodeModal(true)}
          >
            Enter Code
          </Button>
        )}
      </Box>
      <EnterCodeModal
        open={openCodeModal}
        closeModal={() => setOpenCodeModal(false)}
      />
      <Divider
        sx={{
          fontSize: toRem(12),
          color: theme.palette.text.secondary,
          position: 'static',
          backgroundColor: theme.palette.secondary.main,
          height: 'unset',
          width: 'unset',
          my: toRem(32),
        }}
      >
        Social Connection
      </Divider>
      <Box>
        <FieldStyled
          fullWidth
          id="discordName"
          name="discordName"
          label="Discord username:"
          InputProps={{
            sx: {
              paddingLeft: 0,
              borderRadius: '4px 0 0 4px',
            },
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.discordName}
          sx={{ flex: 1, minHeight: '90px' }}
          helperText={touched.discordName ? errors.discordName : ''}
          error={Boolean(errors.discordName) && touched.discordName}
        />

        <FieldStyled
          fullWidth
          id="telegramLink"
          name="telegramLink"
          label="Telegram Link"
          InputProps={{
            sx: {
              paddingLeft: 0,
              borderRadius: '4px 0 0 4px',
            },
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.telegramLink.replace(/\s/g, '')}
          sx={{ flex: 1, minHeight: '90px' }}
          helperText={touched.telegramLink ? errors.telegramLink : ''}
          error={Boolean(errors.telegramLink) && touched.telegramLink}
        />

        <ButtonGradientDark
          disabled={socialButtonDisabled}
          onClick={() => handleUserUpdate()}
          fullWidth
        >
          Change Social Details
        </ButtonGradientDark>
      </Box>
    </Box>
  );
}
export default AccountUserInfo;
