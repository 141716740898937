import { DataGrid } from '@mui/x-data-grid';
import { DataGridCustomStyles } from '@styles/shared/SDataGrid';
import { memo, useState } from 'react';
import { NO_INVESTMENT_MS } from '@constants/CDefaultMessages';
import DataWrapper from '@components/shared/DataWrapper';
import { useGetMyTransactionInvestmentQuery } from '@store/api/transactionInvestmentApi';
import { baseInvestmentTxColumns } from '@components/tables-columns/InvestmentTxColumns';

function InvestmentTable() {
  const [pageSize, setPageSize] = useState<number>(5);
  const [page, setPage] = useState<number>(0);

  const {
    data: responseMyTxInvestment,
    isLoading,
    isFetching,
  } = useGetMyTransactionInvestmentQuery({
    page: page + 1,
    pageSize,
  });

  return (
    <DataWrapper
      isLoading={isLoading || isFetching}
      data={responseMyTxInvestment?.resource || []}
      defaultOptions={{
        fullHeight: true,
        text: NO_INVESTMENT_MS,
      }}
      isLoadingOptions={{
        dimension: 'contained',
        sx: {
          height: '100%',
        },
      }}
    >
      <DataGrid
        sx={DataGridCustomStyles}
        rows={responseMyTxInvestment?.resource || []}
        columns={baseInvestmentTxColumns}
        autoHeight
        disableSelectionOnClick
        paginationMode="server"
        rowCount={responseMyTxInvestment?.count || 0}
        initialState={{
          pagination: { pageSize, page },
        }}
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        disableColumnSelector
        disableDensitySelector
        disableColumnFilter
      />
    </DataWrapper>
  );
}

export default memo(InvestmentTable);
