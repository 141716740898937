import { Box, Typography, SxProps } from '@mui/material';
import useStyles from '@hooks/useStyles';
import { toRem } from '@utils/functions';

function TableCellStyled({
  children,
  title,
  sx,
}: {
  children: JSX.Element | JSX.Element[];
  title: string;
  sx?: SxProps;
}) {
  const { border1pxSecondaryLight } = useStyles();

  return (
    <Box
      sx={{
        p: { xs: 1, md: `${toRem(12)} ${toRem(10)}` },
        borderRight: {
          xs: 'none',
          md: border1pxSecondaryLight,
        },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        ...sx,
        '&:last-child': {
          border: '0',
        },
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 500,
          color: (theme) => theme.palette.text.secondary,
          mb: 1,
        }}
      >
        {title}
      </Typography>

      {children}
    </Box>
  );
}

export default TableCellStyled;
