import { Box, Button, Slider, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import CloseIcon from '@mui/icons-material/Close';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ImageIcon from '@mui/icons-material/Image';
import { flexCentered } from '@styles/shared/SCss';
import { CloseBtn, HeaderModal } from '@styles/modal/SModal';
import ModalCom from '@components/modals/ModalCom';

type Position = {
  x: number;
  y: number;
};

interface Props {
  openModal: boolean;
  closeModal: () => void;
  image: string;
  handleUpdateImage: (data?: File) => Promise<void>;
}

function EditAvatarModal({
  openModal,
  closeModal,
  image,
  handleUpdateImage,
}: Props) {
  const [scale, setScale] = useState<number>(1.1);

  const [currentImage, setCurrentImage] = useState<string | undefined>(image);

  const [position, setPosition] = useState<Position>({ x: 0.5, y: 0.5 });

  const editorRef = useRef<AvatarEditor | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleScale = (e: any) => {
    const scaleFloat = parseFloat(e.target.value);
    setScale(scaleFloat);
  };

  const handlePositionChange = (newPosition: Position) => {
    setPosition(newPosition);
  };

  function randomString(length: number) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const handleSaveImage = async () => {
    if (currentImage) {
      if (editorRef.current) {
        const canvas = editorRef.current.getImageScaledToCanvas();
        canvas.toBlob(async (blob) => {
          if (blob) {
            const canvasFile = new File([blob], randomString(5));
            closeModal();
            await handleUpdateImage(canvasFile);
          }
        });
      }
    } else {
      closeModal();
      await handleUpdateImage(undefined);
    }
  };

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setCurrentImage(url);
    }
  };

  const handleDeleteImage = () => {
    setCurrentImage(undefined);
  };

  return (
    <ModalCom open={openModal} onClose={closeModal}>
      <>
        <HeaderModal>
          <Typography variant="h1" sx={{ fontSize: '1.5rem' }}>
            Edit Profile Picture
          </Typography>
          <CloseBtn onClick={closeModal}>
            <CloseIcon sx={{ width: 25, height: 25, opacity: 0.5 }} />
          </CloseBtn>
        </HeaderModal>
        <Box
          sx={{
            padding: '1.5rem',
            paddingBottom: '0',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          {currentImage ? (
            <AvatarEditor
              ref={editorRef}
              image={currentImage}
              style={{ borderRadius: '6px' }}
              width={250}
              height={250}
              border={0}
              rotate={0}
              borderRadius={500}
              position={position}
              scale={parseFloat(scale.toString())}
              onPositionChange={handlePositionChange}
            />
          ) : (
            <Box
              sx={{
                width: '250px',
                height: '250px',
                backgroundColor: 'background.default',
                borderRadius: '6px',
                ...flexCentered,
                flexDirection: 'column',
                margin: '2rem',
              }}
            >
              <ImageIcon sx={{ fontSize: '100px' }} />
              <label htmlFor="file2">
                <input
                  accept="image/*"
                  hidden
                  id="file2"
                  multiple
                  type="file"
                  onChange={handleChangeImage}
                />
                <Typography
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    fontSize: '13px',
                    fontWeight: 700,
                    ':hover': {
                      textDecoration: 'none',
                    },
                  }}
                >
                  Upload an image
                </Typography>
              </label>
            </Box>
          )}
          {currentImage && (
            <Box display="flex" alignItems="center" gap="0.8rem">
              <ZoomOutIcon sx={{ opacity: 0.5 }} />
              <Slider
                sx={{ width: 180 }}
                defaultValue={1}
                onChange={handleScale}
                step={0.01}
                min={1}
                max={2}
              />
              <ZoomInIcon sx={{ opacity: 0.5 }} />
            </Box>
          )}
          <Button variant="outlined" onClick={handleSaveImage} fullWidth>
            Save Changes
          </Button>
          {currentImage && (
            <Button
              variant="text"
              onClick={handleDeleteImage}
              fullWidth
              sx={{
                color: 'error.main',
                textDecoration: 'underline',
                fontWeight: 400,
              }}
            >
              Delete image
            </Button>
          )}
        </Box>
      </>
    </ModalCom>
  );
}

export default EditAvatarModal;
