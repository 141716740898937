import { Typography, TypographyProps } from '@mui/material';
import { TListing } from '@typescript/models/Listing.model';
import { intervalToDuration, isPast } from 'date-fns';
import { useEffect, useState } from 'react';

interface Props {
  listing: TListing;
  sx?: TypographyProps;
}

function TimeField({ listing, sx }: Props) {
  const [, setSeconds] = useState<number>(0);

  const getFormattedTime = () => {
    const isExpired = isPast(new Date(listing.expireAt)) || listing.isExpired;

    if (isExpired) {
      return 'Expired';
    }

    const duration = intervalToDuration({
      start: new Date(),
      end: new Date(listing.expireAt),
    });

    const { days, hours, minutes } = duration;

    const validDays = days && days > 0 ? `${days}d` : '';
    const validHours = hours && hours > 0 ? `${hours}h` : '';
    const validMinutes = minutes && minutes > 0 ? `${minutes}m` : '';

    if (days && days > 1) {
      return `${validDays} ${validHours}`;
    }

    return `${validHours} ${validMinutes}`;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isPast(new Date(listing.expireAt))) {
        clearInterval(intervalId);
      }
      setSeconds((prev) => prev + 1);
    }, 1000 * 60);

    return () => clearInterval(intervalId);
  }, [listing.expireAt, listing.isExpired]);

  return (
    <Typography sx={{ fontSize: '14px', ...sx }}>
      {getFormattedTime()}
    </Typography>
  );
}

export default TimeField;
