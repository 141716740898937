import { TActivityTab } from '@typescript/TTabs';
import { ActivityStreamContentType } from '@constants/CContentType';
import MyActivityInvestment from '@components/activity/InvestmentTable';
import SoldTable from './SoldTable';
import BoughtTable from './BoughtTable';

interface Props {
  activeTab: TActivityTab;
}

function ActivityTables({ activeTab }: Props) {
  const tableContent = () => {
    switch (activeTab.index) {
      case ActivityStreamContentType.SOLD: {
        return <SoldTable />;
      }
      case ActivityStreamContentType.BOUGHT: {
        return <BoughtTable />;
      }
      case ActivityStreamContentType.INVESTED: {
        return <MyActivityInvestment />;
      }
      default: {
        return null;
      }
    }
  };
  return <>{tableContent()}</>;
}

export default ActivityTables;
