import {
  Menu,
  Button,
  Typography,
  Box,
  Tooltip,
  useTheme,
} from '@mui/material';
import { Svgs } from '@utils/svgs';
import { useEffect, useState } from 'react';
import useUtils from '@hooks/useUtils';
import { HeaderWalletMenuPaperStyle } from '@styles/header/SHeaderWallet';
import customColors from '@theme/customColors';
import { toRem } from '@utils/functions';
import { FieldStyled } from '@styles/modal/SModal';
import useUser from '@hooks/useUser';

interface Props {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

function HeaderWalletModal({ anchorEl, handleClose }: Props) {
  const { currentUser: user } = useUser();
  const theme = useTheme();

  const openMenu = Boolean(anchorEl);

  const { serverNetwork, logOut } = useUtils();

  const [copiedText, setCopiedText] = useState('');

  const [copyState, setCopyState] = useState(false);

  const handleCopyToClipboard = async () => {
    navigator.clipboard.writeText(user?.ethAddress || '');
    setCopiedText(user?.ethAddress || '');
    setCopyState(true);
  };

  const handleLogOut = () => {
    handleClose();
    logOut();
  };
  useEffect(() => {
    if (copiedText !== user?.ethAddress) {
      setCopyState(false);
    }
  }, [copiedText, user]);

  const networkLink =
    serverNetwork === 'bsc'
      ? 'https://bscscan.com/address/'
      : 'https://testnet.bscscan.com/address/';

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={openMenu}
      onClose={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      PaperProps={{ sx: HeaderWalletMenuPaperStyle }}
    >
      <Box
        sx={{
          backgroundColor: customColors.cardGrey,
          border: `1px solid ${theme.palette.primary.light}`,
          borderRadius: theme.spacing(1),
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 500, padding: toRem(14) }}>
          Your Wallet
        </Typography>

        <Box
          sx={{
            height: '1px',
            width: '100%',
            backgroundColor: theme.palette.primary.light,
          }}
        />

        <Box sx={{ padding: toRem(14) }}>
          <Box display="flex" marginBottom={toRem(15)}>
            <FieldStyled
              fullWidth
              label="Wallet Address"
              InputProps={{
                readOnly: true,
                sx: {
                  paddingLeft: 0,
                  borderRadius: '4px 0 0 4px',
                  height: toRem(50),
                  minWidth: toRem(420),
                },
              }}
              value={user?.ethAddress}
            />

            <Box>
              <Tooltip
                title={copyState ? 'Copied!' : 'Copy'}
                arrow
                placement="top"
              >
                <Button
                  sx={{
                    padding: '0.7rem 0.5rem',
                    borderRadius: '0 6px 6px 0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: `1px solid ${theme.palette.primary.light}`,
                    height: '100%',
                    width: toRem(64),
                  }}
                  onClick={handleCopyToClipboard}
                >
                  <Svgs.Copy />
                </Button>
              </Tooltip>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: '0.7rem' }}>
            <Button variant="outlined" onClick={handleLogOut} fullWidth>
              Log Out
            </Button>

            <Button
              variant="outlined"
              href={`${networkLink}${user?.ethAddress}`}
              target="_blank"
              fullWidth
            >
              View BSCScan
            </Button>
          </Box>
        </Box>
      </Box>
    </Menu>
  );
}

export default HeaderWalletModal;
