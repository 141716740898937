import { OfferStatus } from '@constants/COffer';
import { Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid';
import { DataGridCustomStyles, gridRow } from '@styles/shared/SDataGrid';
import { TOffer } from '@typescript/models/Offer.model';
import { formatNr, sliceWalletAddress } from '@utils/functions';
import { memo } from 'react';

const offersColumns = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: (offer: TOffer) => void,
  userWallet: string,
): GridColumns<TOffer> => [
  {
    ...gridRow,
    field: 'ID',
    headerName: 'ID',
    valueGetter: ({ row }) => row.id,
  },
  {
    ...gridRow,
    field: 'Listing',
    headerName: 'Listing',
    valueGetter: ({ row }) => row.listing.id,
  },
  {
    ...gridRow,
    field: 'Project',
    headerName: 'Project',
    valueGetter: ({ row }) => row.listing.project.name,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.listing.project.id}>
        <Typography>{row.listing.project.name}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Offerer',
    headerName: 'Offerer',
    valueGetter: ({ row }) => row.offerer.ethAddress,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.offerer.ethAddress}>
        <Typography>
          {row.offerer.ethAddress === userWallet
            ? 'USER'
            : sliceWalletAddress(row.offerer.ethAddress)}
        </Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Listing User',
    headerName: 'Listing User',
    valueGetter: ({ row }) => row.listing.user.ethAddress,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.listing.user.ethAddress}>
        <Typography>
          {row.listing.user.ethAddress === userWallet
            ? 'USER'
            : sliceWalletAddress(row.listing.user.ethAddress)}
        </Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Transaction',
    headerName: 'Transaction',
    valueGetter: ({ row }) => row.listing.transaction?.id || '-',
  },
  {
    ...gridRow,
    field: 'Offer Token Price',
    headerName: 'Offer Token Price',
    valueGetter: ({ row }) => row.offeredTokenPrice,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.offeredTokenPrice}>
        <Typography>{formatNr(row.offeredTokenPrice, true, 2)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Offer Total Price',
    headerName: 'Offer Total Price',
    valueGetter: ({ row }) => row.offeredPrice,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.offeredPrice}>
        <Typography>{formatNr(+row.offeredPrice, true, 2)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Status',
    headerName: 'Status',
    valueGetter: ({ row }) => OfferStatus[row.status],
  },
  {
    ...gridRow,
    field: 'Rejected By',
    headerName: 'Rejected By',
    valueGetter: ({ row }) => row.rejectedBy?.id || '-',
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.rejectedBy?.ethAddress || '-'}>
        <Typography>{row.rejectedBy?.id || '-'}</Typography>
      </Tooltip>
    ),
  },
];

interface Props {
  action: (listing: TOffer) => void;
  rows: TOffer[];
  userWallet: string;
}

function AdminUserOffersTable({ action, rows, userWallet }: Props) {
  return (
    <DataGrid
      sx={DataGridCustomStyles}
      rows={rows}
      columns={offersColumns(action, userWallet)}
      disableSelectionOnClick
      autoHeight
      pageSize={30}
      rowsPerPageOptions={[30]}
      disableColumnSelector
      disableDensitySelector
      getRowId={(row) => row.id}
      components={{
        Toolbar: GridToolbar,
      }}
    />
  );
}

export default memo(AdminUserOffersTable);
