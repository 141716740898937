import { Box, Typography } from '@mui/material';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import LinkBox from '@components/help-center/LinkBox';
import { THelpCenterSearchResult } from '@typescript/TSearch';

interface Props {
  results: THelpCenterSearchResult[];
  isLoading: boolean;
}

function HelpCenterSearchResults({ results, isLoading }: Props) {
  if (isLoading) return <IsLoadingCom dimension="medium" />;
  return (
    <Box sx={{ display: 'grid', gap: '14px', mt: '14px' }}>
      {results.length ? (
        results.map(({ url, category }) => (
          <LinkBox
            key={url + category}
            href={url}
            title={category}
            description="Open Legion Ventures Docs"
          />
        ))
      ) : (
        <Typography
          variant="h5"
          sx={{ color: 'primary.main', ml: '0.5rem', mb: '14px' }}
        >
          No results found..
        </Typography>
      )}
    </Box>
  );
}
export default HelpCenterSearchResults;
