/* eslint-disable no-underscore-dangle */
import { Box, Avatar, Button } from '@mui/material';
import { useState } from 'react';
import useUser from '@hooks/useUser';
import EditAvatarModal from '@components/account/EditAvatarModal';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import { Svgs } from '@utils/svgs';

function ProfileImage() {
  const { updateImage, currentUser } = useUser();

  const [image, setImage] = useState<string | null>(null);

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdateImage = async (imgFile?: File) => {
    setIsLoading(true);
    await updateImage(imgFile);
    setIsLoading(false);
  };

  const handleChangeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setImage(url);
      setOpenEditModal(true);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '6px',
      }}
    >
      {isLoading ? (
        <IsLoadingCom dimension="contained" />
      ) : (
        <>
          {openEditModal && image && (
            <EditAvatarModal
              openModal={openEditModal}
              closeModal={() => setOpenEditModal(false)}
              image={image}
              handleUpdateImage={handleUpdateImage}
            />
          )}
          <label htmlFor="file">
            <input
              accept="image/*"
              hidden
              id="file"
              multiple
              type="file"
              onChange={handleChangeImage}
            />
            <Button component="span">
              {currentUser?.profileImage ? (
                <Box>
                  <Avatar
                    sx={{
                      width: 150,
                      height: 150,
                      border: '1px solid #3B3B60',
                      position: 'relative',
                    }}
                    src={currentUser?.profileImage}
                  />
                  <Svgs.Settings
                    width={32}
                    height={32}
                    style={{
                      backgroundColor: '#313169',
                      padding: '3px',
                      border: '1px solid #49498B',
                      borderRadius: '4px',
                      position: 'absolute',
                      bottom: '10px',
                      right: '20px',
                    }}
                  />
                </Box>
              ) : (
                <Box>
                  <Avatar
                    sx={{
                      width: { xs: 75, xsm: 150 },
                      height: { xs: 75, xsm: 150 },
                      border: '1px solid #3B3B60',
                    }}
                  />
                </Box>
              )}
            </Button>
          </label>
        </>
      )}
    </Box>
  );
}

export default ProfileImage;
