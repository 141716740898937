import { Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid';
import { DataGridCustomStyles, gridRow } from '@styles/shared/SDataGrid';
import { TAllocation } from '@typescript/models/Allocation.model';
import { formatNr, sliceWalletAddress } from '@utils/functions';
import { memo } from 'react';

const addressCell = (address: string | undefined) => {
  if (!address) {
    return <Typography>-</Typography>;
  }
  return (
    <Tooltip placement="top" title={address}>
      <Typography>{sliceWalletAddress(address)}</Typography>
    </Tooltip>
  );
};

const allocationColumns = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: (allocation: TAllocation) => void,
): GridColumns<TAllocation> => [
  {
    ...gridRow,
    field: 'ID',
    headerName: 'ID',
    valueGetter: ({ row }) => row.id,
  },
  {
    ...gridRow,
    field: 'Project',
    headerName: 'Project',
    valueGetter: ({ row }) => row.project.name,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.project.name}>
        <Typography>{row.project.name}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'User',
    headerName: 'User',
    valueGetter: ({ row }) => row.user?.id || '-',
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.user?.ethAddress || '-'}>
        <Typography>
          {row.user?.id ? sliceWalletAddress(row.user.id) : '-'}
        </Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Excel Address',
    headerName: 'Excel Address',
    valueGetter: ({ row }) => row.excelWalletAddress,
    renderCell: ({ row }) => addressCell(row.excelWalletAddress),
  },
  {
    ...gridRow,
    field: 'Locked Tokens',
    headerName: 'Locked Tokens',
    valueGetter: ({ row }) => row.lockedTokens,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.lockedTokens}>
        <Typography>{formatNr(row.lockedTokens, undefined, 2)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Locked Value',
    headerName: 'Locked Value',
    valueGetter: ({ row }) => row.lockedValue,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.lockedValue}>
        <Typography>{formatNr(row.lockedValue, true, 2)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Investment',
    headerName: 'Investment',
    valueGetter: ({ row }) => row.investmentAmount,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.investmentAmount}>
        <Typography>{formatNr(row.investmentAmount, true, 2)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Investment After Fee',
    headerName: 'Investment After Fee',
    valueGetter: ({ row }) => row.investmentAmountWithFee,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.investmentAmountWithFee}>
        <Typography>
          {formatNr(row.investmentAmountWithFee, true, 2)}
        </Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Social',
    headerName: 'Social',
    valueGetter: ({ row }) => row.social || '-',
  },
  {
    ...gridRow,
    field: 'Total Tokens',
    headerName: 'Total Tokens',
    valueGetter: ({ row }) => row.totalTokens,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.totalTokens}>
        <Typography>{formatNr(row.totalTokens, undefined, 2)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'AssetWorth',
    headerName: 'AssetWorth',
    valueGetter: ({ row }) => row.assetWorth,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.assetWorth}>
        <Typography>{formatNr(row.assetWorth, true, 2)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Percentage',
    headerName: 'Percentage',
    valueGetter: ({ row }) => row.percentage,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.percentage}>
        <Typography>{formatNr(row.percentage, undefined, 2)}%</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Unlocked Tokens',
    headerName: 'Unlocked Tokens',
    valueGetter: ({ row }) => row.unlockedTokens,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.unlockedTokens}>
        <Typography>{formatNr(row.unlockedTokens, undefined, 2)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Unlocked Value',
    headerName: 'Unlocked Value',
    valueGetter: ({ row }) => row.unlockedValue,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.unlockedValue}>
        <Typography>{formatNr(row.unlockedValue, true, 2)}</Typography>
      </Tooltip>
    ),
  },
];

interface Props {
  action: (tx: TAllocation) => void;
  rows: TAllocation[];
}

function AdminUserAllocationsTable({ action, rows }: Props) {
  return (
    <DataGrid
      sx={DataGridCustomStyles}
      rows={rows}
      columns={allocationColumns(action)}
      disableSelectionOnClick
      autoHeight
      pageSize={30}
      rowsPerPageOptions={[30]}
      disableColumnSelector
      disableDensitySelector
      getRowId={(row) => row.id}
      components={{
        Toolbar: GridToolbar,
      }}
    />
  );
}

export default memo(AdminUserAllocationsTable);
