import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import useUser from '@hooks/useUser';
import { Svgs } from '@utils/svgs';
import useUtils from '@hooks/useUtils';
import ModalCom from '@components/modals/ModalCom';
import { toRem } from '@utils/functions';
import { FieldStyled } from '@styles/modal/SModal';

interface Props {
  open: boolean;
  closeModal: () => void;
}

function HeaderWalletResponsiveModal({ open, closeModal }: Props) {
  const { currentUser } = useUser();

  const { palette } = useTheme();

  const { serverNetwork, logOut } = useUtils();

  const [copiedText, setCopiedText] = useState('');

  const [copyState, setCopyState] = useState(false);

  const handleCopyToClipboard = async () => {
    if (currentUser) {
      navigator.clipboard.writeText(currentUser.ethAddress);
      setCopiedText(currentUser.ethAddress);
      setCopyState(true);
    }
  };

  const handleLogOut = () => {
    closeModal();
    logOut();
  };

  useEffect(() => {
    if (currentUser && copiedText !== currentUser.ethAddress) {
      setCopyState(false);
    }
  }, [copiedText, currentUser]);

  const networkLink =
    serverNetwork === 'bsc'
      ? 'https://bscscan.com/address/'
      : 'https://testnet.bscscan.com/address/';

  return (
    <Box>
      <ModalCom
        open={open}
        onClose={closeModal}
        containerSx={{
          minWidth: 500,
        }}
      >
        <Box>
          <Typography variant="h4" sx={{ fontWeight: 500, padding: toRem(14) }}>
            Your wallet
          </Typography>

          <Box
            sx={{
              height: '1px',
              width: '100%',
              backgroundColor: palette.primary.light,
            }}
          />

          <Box sx={{ padding: toRem(14) }}>
            <Box display="flex" marginBottom={toRem(15)}>
              <FieldStyled
                fullWidth
                label="Wallet Address"
                InputProps={{
                  readOnly: true,
                  sx: {
                    paddingLeft: 0,
                    borderRadius: '4px 0 0 4px',
                    height: toRem(50),
                    minWidth: { md: toRem(420) },
                  },
                }}
                value={currentUser?.ethAddress}
              />

              <Box>
                <Tooltip
                  title={copyState ? 'Copied!' : 'Copy'}
                  arrow
                  placement="top"
                >
                  <Button
                    sx={{
                      padding: '0.7rem 0.5rem',
                      borderRadius: '0 6px 6px 0',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: `1px solid ${palette.primary.light}`,
                      borderLeft: '0',
                      height: '100%',
                      width: toRem(64),
                    }}
                    onClick={handleCopyToClipboard}
                  >
                    <Svgs.Copy />
                  </Button>
                </Tooltip>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: '0.7rem',
                flexDirection: { xs: 'column', xsm: 'row' },
              }}
            >
              <Button variant="outlined" onClick={handleLogOut} fullWidth>
                Log Out
              </Button>

              <Button
                variant="outlined"
                href={`${networkLink}${currentUser}`}
                target="_blank"
                fullWidth
              >
                View BSCScan
              </Button>
            </Box>
          </Box>
        </Box>
      </ModalCom>
    </Box>
  );
}
export default HeaderWalletResponsiveModal;
