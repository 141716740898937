import { Box, Button, useTheme } from '@mui/material';
import { TResponsiveMenu } from '@typescript/TSidebar';

interface Props {
  item: TResponsiveMenu;
  handleNavigate: (item: TResponsiveMenu) => void;
  isActive: (path: string) => boolean;
  handleOpenHelpCenter: () => void;
}

function HeaderNavbarResponsiveItem({
  item,
  handleNavigate,
  isActive,
  handleOpenHelpCenter,
}: Props) {
  const { palette } = useTheme();

  const Icon = item.icon;

  const isHelpCenterButton = item.name === 'Help & Support';

  return (
    <Box sx={{ px: '18px', mb: 1 }} key={item.name}>
      <Box
        onClick={() =>
          isHelpCenterButton ? handleOpenHelpCenter() : handleNavigate(item)
        }
        sx={{
          borderTop: `1px solid ${palette.secondary.light}`,
          padding: '12px 15px',
          border: 'none !important',
          borderRadius: '6px',
          display: 'flex',
          alignItems: 'center',
          cursor: item.disabled ? 'unset' : 'pointer',
          gap: '0.5rem',
          backgroundColor: isActive(item.href)
            ? palette.secondary.light
            : 'transparent',
          ':last-of-type': {
            borderBottom: `1px solid ${palette.secondary.light}`,
            ':hover': {
              backgroundColor: item.disabled
                ? 'unset'
                : palette.secondary.light,
            },
          },
        }}
      >
        <Icon
          fill={palette.primary.main}
          style={{
            width: 18,
            height: 18,
            opacity: item.disabled ? 0.5 : 1,
          }}
        />
        <Button
          disabled={item.disabled}
          sx={{
            textDecoration: 'none',
            padding: '0',
            fontSize: '13px',
            fontWeight: 'bold',
          }}
        >
          {item.name}
        </Button>
      </Box>
    </Box>
  );
}

export default HeaderNavbarResponsiveItem;
