import { Box } from '@mui/material';
import { NO_INVESTMENT_MS } from '@constants/CDefaultMessages';
import DataWrapper from '@components/shared/DataWrapper';
import { useState } from 'react';
import { paginationZod } from '@typescript/dtos/shared/pagination.dto';
import { useGetEthInvestmentsQuery } from '@store/api/ethInvestmentApi';
import EthInvestmentCards from './EthInvestmentCards';

function MyEthInvestmentTableResponsive() {
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const {
    data: { resource: myTxInvestment, count } = { resource: [], count: 0 },
    isFetching,
    isLoading,
  } = useGetEthInvestmentsQuery(paginationZod.parse({ page, pageSize }));

  return (
    <Box
      sx={{
        display: 'grid',
        gap: '0.5rem',
        width: '100%',
        maxWidth: { xs: '450px', md: '711px', lg: 'unset' },
        marginInline: 'auto',
        mt: 1,
        height: '100%',
      }}
    >
      <DataWrapper
        isLoading={isLoading || isFetching}
        data={myTxInvestment}
        defaultOptions={{
          fullHeight: true,
          text: NO_INVESTMENT_MS,
        }}
        isLoadingOptions={{
          dimension: 'contained',
        }}
      >
        <EthInvestmentCards
          ethInvestments={myTxInvestment}
          actionSeeMore={() => {
            setPage(1);
            setPageSize((prev) => prev + 10);
          }}
          count={count}
          isLoading={isLoading}
        />
      </DataWrapper>
    </Box>
  );
}
export default MyEthInvestmentTableResponsive;
