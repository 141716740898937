import { Box, Typography } from '@mui/material';
import { flexCentered } from '@styles/shared/SCss';
import colors from '@theme/customColors';
import { Svgs } from '@utils/svgs';
import { TListing } from '@typescript/models/Listing.model';
import { TOffer } from '@typescript/models/Offer.model';
import useStyles from '@hooks/useStyles';
import { TProject } from '@typescript/models/Project.model';

interface Props {
  project?: TProject;
  offer?: TOffer;
  listing?: TListing;
  getOfferedPrice?: (listing: TListing) => string;
  handleNavigate: (projectName: string) => void;
}

function SearchResultCard({
  project,
  offer,
  listing,
  getOfferedPrice,
  handleNavigate,
}: Props) {
  const { border1pxSecondaryLight } = useStyles();

  const containerStyle = {
    backgroundColor: colors.riverStyxDarkBlue,
    display: 'flex',
    border: border1pxSecondaryLight,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 0.5rem',
    borderRadius: '6px',
    ':hover': {
      backgroundColor: '#151624',
      borderColor: '#151624',
      cursor: 'pointer',
    },
  };

  const textStyle = {
    fontSize: '15px',
    color: 'primary.main',
  };

  const arrowStyle = {
    width: 24,
    height: 24,
    ...flexCentered,
    borderRadius: '6px',
    backgroundColor: 'rgba(141, 153, 174, 0.15)',
  };

  return (
    <Box>
      {project && (
        <Box
          sx={{ ...containerStyle }}
          key={project.id}
          onClick={() => handleNavigate(project.slug)}
        >
          <Box sx={{ ...flexCentered, gap: '0.5rem' }}>
            <Typography variant="h5" sx={textStyle}>
              {project.name}
            </Typography>
            <Typography variant="h5" sx={{ ...textStyle, opacity: 0.5 }}>
              {project.symbol}
            </Typography>
          </Box>
          <Box sx={arrowStyle}>
            <Svgs.ArrowRight style={{ opacity: 0.5 }} />
          </Box>
        </Box>
      )}
      {listing && (
        <Box
          sx={{
            border: border1pxSecondaryLight,
            borderRadius: '6px',
          }}
        >
          <Box
            className="Header"
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#17172B',
              padding: '1rem',
              borderRadius: '6px 6px 0 0',
              borderBottom: border1pxSecondaryLight,
            }}
          >
            <img
              width={48}
              height={48}
              src={listing.project.image}
              alt=""
              style={{ borderRadius: '6px' }}
            />
            <Typography
              onClick={() => handleNavigate(listing.project.slug)}
              sx={{
                fontSize: '17px',
                textDecoration: 'underline',
                marginLeft: '0.5rem',
                cursor: 'pointer',
              }}
            >
              {listing.project.name}
            </Typography>
          </Box>
          <Box
            className="Body"
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              backgroundColor: 'secondary.main',
            }}
          >
            <Box
              className="Info Box"
              sx={{
                padding: '1rem',
                borderRight: border1pxSecondaryLight,
                borderBottom: border1pxSecondaryLight,
              }}
            >
              <Typography
                pb={2}
                variant="h5"
                sx={{ color: 'primary.main', opacity: 0.5 }}
              >
                Current Value
              </Typography>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                $ {listing.project.currentPrice}
              </Typography>
            </Box>
            <Box
              className="Info Box"
              sx={{ padding: '1rem', borderBottom: border1pxSecondaryLight }}
            >
              <Typography
                pb={2}
                variant="h5"
                sx={{ color: 'primary.main', opacity: 0.5 }}
              >
                Price Asked
              </Typography>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                $ {listing.price}
              </Typography>
            </Box>
            <Box
              className="Info Box"
              sx={{ padding: '1rem', borderRight: border1pxSecondaryLight }}
            >
              <Typography
                pb={2}
                variant="h5"
                sx={{ color: 'primary.main', opacity: 0.5 }}
              >
                Price Offered
              </Typography>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                {getOfferedPrice?.(listing)}
              </Typography>
            </Box>
          </Box>
          <Box
            className="Footer"
            sx={{
              backgroundColor: '#17172B',
              padding: '1rem',
              borderRadius: '0px 0px 6px 6px',
              borderTop: border1pxSecondaryLight,
              ...flexCentered,
            }}
          >
            <Typography
              onClick={() => handleNavigate(listing.project.slug)}
              sx={{
                fontSize: '13px',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              See Project
            </Typography>
          </Box>
        </Box>
      )}
      {offer && (
        <Box
          sx={{
            border: border1pxSecondaryLight,
            borderRadius: '6px',
          }}
        >
          <Box
            className="Header"
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#17172B',
              padding: '1rem',
              borderRadius: '6px 6px 0 0',
              borderBottom: border1pxSecondaryLight,
            }}
          >
            <img
              width={48}
              height={48}
              src={offer.listing.project.image}
              alt=""
              style={{ borderRadius: '6px' }}
            />
            <Typography
              onClick={() => handleNavigate(offer.listing.project.slug)}
              sx={{
                fontSize: '17px',
                textDecoration: 'underline',
                marginLeft: '0.5rem',
                cursor: 'pointer',
              }}
            >
              {offer.listing.project.name}
            </Typography>
          </Box>
          <Box
            className="Body"
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              backgroundColor: 'secondary.main',
            }}
          >
            <Box
              className="Info Box"
              sx={{
                padding: '1rem',
                borderRight: border1pxSecondaryLight,
                borderBottom: border1pxSecondaryLight,
              }}
            >
              <Typography
                pb={2}
                variant="h5"
                sx={{ color: 'primary.main', opacity: 0.5 }}
              >
                Current Value
              </Typography>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                $ {offer.listing.project.currentPrice}
              </Typography>
            </Box>
            <Box
              className="Info Box"
              sx={{ padding: '1rem', borderBottom: border1pxSecondaryLight }}
            >
              <Typography
                pb={2}
                variant="h5"
                sx={{ color: 'primary.main', opacity: 0.5 }}
              >
                Price Asked
              </Typography>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                $ {offer.listing.price}
              </Typography>
            </Box>
            <Box
              className="Info Box"
              sx={{ padding: '1rem', borderRight: border1pxSecondaryLight }}
            >
              <Typography
                pb={2}
                variant="h5"
                sx={{ color: 'primary.main', opacity: 0.5 }}
              >
                Price Offered
              </Typography>
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                {offer.offeredPrice}
              </Typography>
            </Box>
          </Box>
          <Box
            className="Footer"
            sx={{
              backgroundColor: '#17172B',
              padding: '1rem',
              borderRadius: '0px 0px 6px 6px',
              borderTop: border1pxSecondaryLight,
              ...flexCentered,
            }}
          >
            <Typography
              onClick={() => handleNavigate(offer.listing.project.name)}
              sx={{
                fontSize: '13px',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
            >
              See Project
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default SearchResultCard;
