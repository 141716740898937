import {
  Box,
  MenuItem,
  Select,
  styled,
  Typography,
  useTheme,
} from '@mui/material';

import { useEffect, useMemo, useState } from 'react';
import { flexCentered, shadedWhite } from '@styles/shared/SCss';
import { Svgs } from '@utils/svgs';
import useUtils from '@hooks/useUtils';
import { ChainId } from '@constants/CChains';
import { ServerNetwork, toRem } from '@utils/functions';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import useNotification from '@hooks/useNotification';
import { useChain } from 'react-moralis';

const ChainSelect = styled(Select)(() => ({
  backgroundColor: 'transparent',
  '& .MuiSelect-select': {
    padding: '5px !important',
  },
  '& .MuiSvgIcon-root': {
    width: '20px',
    height: '20px',
    ...shadedWhite,
  },
}));

type SelectedChain = {
  id: string;
  name: string;
  svg: () => JSX.Element;
  visible: boolean;
};

const unsupportedChain: SelectedChain = {
  id: 'unsupported',
  name: 'Unsupported',
  svg: () => <Svgs.BNB width={19} height={19} />,
  visible: false,
};

const selectMainNetChains: SelectedChain[] = [
  {
    id: ChainId.BSC,
    name: 'BNB Mainnet',
    svg: () => <Svgs.BNB width={19} height={19} />,
    visible: true,
  },
  {
    id: ChainId.ETH,
    name: 'Ethereum Mainnet',
    svg: () => <Svgs.ETH width={19} height={19} />,
    visible: true,
  },
  {
    id: ChainId.POLYGON,
    name: 'Polygon Mainnet',
    svg: () => <Svgs.Polygon width={19} height={19} />,
    visible: true,
  },
  unsupportedChain,
];

const selectTestNetChains: SelectedChain[] = [
  {
    id: ChainId.BSC_TEST_NET,
    name: 'Bsc Testnet',
    svg: () => <Svgs.BNB width={19} height={19} />,
    visible: true,
  },
  {
    id: ChainId.SEPOLIA,
    name: 'Sepolia',
    svg: () => <Svgs.ETH width={19} height={19} />,
    visible: true,
  },
  unsupportedChain,
];

function NetworkSelectComp() {
  const { notifyInfo } = useNotification();

  const { switchNetwork, chainId } = useChain();

  const { serverNetwork } = useUtils();

  const selectChains = useMemo(
    () =>
      serverNetwork === ServerNetwork.BSC_TESTNET
        ? selectTestNetChains
        : selectMainNetChains,
    [serverNetwork],
  );

  const [currentChain, setCurrentChain] =
    useState<SelectedChain>(unsupportedChain);

  const theme = useTheme();

  const SelectMenuProps = {
    sx: {
      padding: '10px',
      top: '16px',
      left: '-2px',
      '.MuiPaper-root': {
        minWidth: '170px !important',
        backdropFilter: 'blur(20px)',
        border: `1px solid ${theme.palette.secondary.light} !important`,
        borderRadius: toRem(8),
      },
      '.MuiList-root': {
        padding: '5px',
        '& .MuiButtonBase-root + .MuiButtonBase-root': {
          marginTop: '5px',
        },
      },
      '.Mui-selected': {
        backgroundColor: `${theme.palette.primary.contrastText} !important`,
        color: `${theme.palette.text.primary} !important`,
      },
      '.MuiButtonBase-root': {
        padding: '5px',
        color: theme.palette.text.secondary,
        borderRadius: toRem(4),
        fontSize: 13,
        ':hover': {
          backgroundColor: theme.palette.primary.contrastText,
        },

        '& .MuiTypography-root': {
          color: 'inherit',
        },
      },
    },
  };

  const handleSelectChainChange = async (chainID: string) => {
    try {
      const chainData = selectChains.find((chain) => chain.id === chainID);

      if (!chainData) {
        throw Error(`No chainData found with chain id ${chainID}.`);
      }

      if (chainData.id !== 'unsupported') {
        await switchNetwork(chainID);
      }

      setCurrentChain(chainData);
    } catch (error) {
      notifyInfo('Request rejected!');
      throw error;
    }
  };

  useEffect(() => {
    if (chainId) {
      const validChain = selectChains.find((chain) => chain.id === chainId);
      if (!validChain) {
        setCurrentChain(unsupportedChain);
      } else {
        setCurrentChain(validChain);
      }
    }
  }, [chainId, selectChains]);

  return (
    <Box
      sx={{
        paddingLeft: { lg: '1rem', xs: '0' },
        ...flexCentered,
      }}
    >
      <ChainSelect
        sx={{
          minWidth: { xs: '160px', lg: '170px' },
          '& .MuiTypography-root': {
            color: theme.palette.text.secondary,
          },
        }}
        value={currentChain.id}
        onChange={(event) =>
          handleSelectChainChange(event.target.value as string)
        }
        MenuProps={SelectMenuProps}
        IconComponent={ExpandMoreRoundedIcon}
      >
        {selectChains.map(({ id, name, svg: Svg, visible }) => (
          <MenuItem
            key={id}
            value={id}
            disableGutters
            sx={{ paddingLeft: '0.5rem', display: visible ? 'block' : 'none' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <Svg />
              <Typography variant="h5" sx={{ color: 'primary.main' }}>
                {name}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </ChainSelect>
    </Box>
  );
}
export default NetworkSelectComp;
