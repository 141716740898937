import { Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid';
import { DataGridCustomStyles, gridRow } from '@styles/shared/SDataGrid';
import { TTransactionInvestment } from '@typescript/models/InvestmentTransaction.model';
import { formatNr, sliceWalletAddress } from '@utils/functions';
import moment from 'moment';
import { memo } from 'react';

const investmentsColumns = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: (tx: TTransactionInvestment) => void,
): GridColumns<TTransactionInvestment> => [
  {
    ...gridRow,
    field: 'ID',
    headerName: 'ID',
    valueGetter: ({ row }) => row.id,
  },
  {
    ...gridRow,
    field: 'Project',
    headerName: 'Project',
    valueGetter: ({ row }) => row.project.name,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.project.id}>
        <Typography>{row.project.name}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Deposit Wallet',
    headerName: 'Deposit Wallet',
    valueGetter: ({ row }) => row.to,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.to}>
        <Typography>{sliceWalletAddress(row.to)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Investment',
    headerName: 'Investment',
    valueGetter: ({ row }) => row.investmentAmount,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.investmentAmount}>
        <Typography>{formatNr(row.investmentAmount, true)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Investment After Fee',
    headerName: 'Investment After Fee',
    valueGetter: ({ row }) => row.investmentAmountWithFee,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.investmentAmountWithFee}>
        <Typography>{formatNr(row.investmentAmountWithFee, true)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Hash',
    headerName: 'Hash',
    valueGetter: ({ row }) => row.transactionHash,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.transactionHash}>
        <Typography>{sliceWalletAddress(row.transactionHash)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: '% Fee',
    headerName: '% Fee',
    valueGetter: ({ row }) => row.feeInPercentage,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.feeInPercentage}>
        <Typography>{formatNr(row.feeInPercentage)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Received Tokens',
    headerName: 'Received Tokens',
    valueGetter: ({ row }) => row.totalTokens,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.totalTokens}>
        <Typography>{formatNr(row.totalTokens)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Created At',
    headerName: 'Created At',
    valueGetter: ({ row }) => moment(row.createdAt).format('YYYY-MM-DD HH:mm'),
  },
];

interface Props {
  action: (tx: TTransactionInvestment) => void;
  rows: TTransactionInvestment[];
}

function AdminUserInvestmentsTable({ action, rows }: Props) {
  return (
    <DataGrid
      sx={DataGridCustomStyles}
      rows={rows}
      columns={investmentsColumns(action)}
      disableSelectionOnClick
      autoHeight
      pageSize={30}
      rowsPerPageOptions={[30]}
      disableColumnSelector
      disableDensitySelector
      getRowId={(row) => row.id}
      components={{
        Toolbar: GridToolbar,
      }}
    />
  );
}

export default memo(AdminUserInvestmentsTable);
