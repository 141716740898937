import { DataGrid } from '@mui/x-data-grid';
import { DataGridCustomStyles } from '@styles/shared/SDataGrid';
import { memo, useState } from 'react';
import { useGetMyTradeTxByTypeQuery } from '@store/api/transactionApi';
import { getTradeTxByTypeZod } from '@typescript/dtos/transaction/getTransaction.dto';
import { GetTradeTxType } from '@typescript/models/TradeTransaction.model';
import DataWrapper from '@components/shared/DataWrapper';
import { NO_BOUGHT_MS } from '@constants/CDefaultMessages';
import { baseTradeTxColumns } from '@components/tables-columns/TradeTxColumns';
import DataGridWrapper from '@components/shared/DataGridWrapper';

function BoughtTable() {
  const [page, setPage] = useState<number>(0);

  const [pageSize, setPageSize] = useState<number>(5);

  const {
    data: { resource: myTradeTx, count } = { resource: [], count: 0 },
    isLoading,
    isFetching,
  } = useGetMyTradeTxByTypeQuery(
    getTradeTxByTypeZod.parse({
      page: page + 1,
      pageSize,
      type: GetTradeTxType.BOUGHT,
    }),
  );

  return (
    <DataWrapper
      isLoading={isLoading}
      data={myTradeTx}
      defaultOptions={{
        fullHeight: true,
        text: NO_BOUGHT_MS,
      }}
      isLoadingOptions={{
        dimension: 'contained',
        sx: {
          height: '100%',
        },
      }}
    >
      <DataGridWrapper isFetching={isFetching}>
        <DataGrid
          sx={DataGridCustomStyles}
          rows={myTradeTx}
          columns={baseTradeTxColumns}
          autoHeight
          loading={isLoading || isFetching}
          initialState={{
            pagination: { pageSize, page },
          }}
          paginationMode="server"
          rowCount={count}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
          disableColumnSelector
          disableDensitySelector
          disableColumnFilter
          disableSelectionOnClick
        />
      </DataGridWrapper>
    </DataWrapper>
  );
}

export default memo(BoughtTable);
