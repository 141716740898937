import { TabBtn, activeTabStyle } from '@styles/shared/SButtons';
import { TabContainer } from '@styles/shared/SBoxes';
import { ListingType } from '@constants/CListings';
import { TListingTab } from '@typescript/TTabs';
import { memo } from 'react';

interface Props {
  handleChangeTabIndex: (currentTab: TListingTab) => void;
  tab: TListingTab;
}

function CustomTabs({ handleChangeTabIndex, tab }: Props) {
  return (
    <TabContainer
      sx={{ display: 'inline-flex', mt: 1, width: 'unset !important' }}
    >
      <TabBtn
        onClick={() =>
          handleChangeTabIndex({
            index: ListingType.SELL,
            name: 'WTS',
          })
        }
        sx={tab.index === ListingType.SELL ? activeTabStyle : {}}
      >
        Sellers
      </TabBtn>
      <TabBtn
        onClick={() =>
          handleChangeTabIndex({
            index: ListingType.BUY,
            name: 'WTB',
          })
        }
        sx={tab.index === ListingType.BUY ? activeTabStyle : {}}
      >
        Buyers
      </TabBtn>
    </TabContainer>
  );
}

export default memo(CustomTabs);
