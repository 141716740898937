import {
  Box,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Svgs } from '@utils/svgs';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { TListing } from '@typescript/models/Listing.model';
import { flexCentered } from '@styles/shared/SCss';
import {
  SearchModalContainer,
  SelectMenuProps,
} from '@styles/header/SHeaderSearch';
import { SearchBar } from '@styles/help-center/SHelpCenter';
import { SearchBox, SearchSelect } from '@styles/header/SHeaderStyles';
import SearchResultCard from '@components/header/search/SearchResultCard';
import { TGlobalSearchResult } from '@typescript/TSearch';
import { GlobalSearchOptions } from '@constants/CSearch';
import { useLazySearchProjectsQuery } from '@store/api/projectApi';
import { useLazySearchListingsQuery } from '@store/api/listingApi';
import { useLazySearchOffersQuery } from '@store/api/offerApi';
import { nameZod } from '@typescript/dtos/shared/id.dto';
import { useDebounce } from 'react-use';

interface Props {
  open: boolean;
  closeModal: () => void;
  getMaxOfferedPrice: (listing: TListing) => number | null;
}

function SearchResponsiveModal({
  open,
  closeModal,

  getMaxOfferedPrice,
}: Props) {
  const { palette } = useTheme();

  const [triggerSearchByProject, { isFetching: isFetchingProjects }] =
    useLazySearchProjectsQuery();

  const [triggerSearchByListing, { isFetching: isFetchingListing }] =
    useLazySearchListingsQuery();

  const [triggerSearchByOffers, { isFetching: isFetchingOffers }] =
    useLazySearchOffersQuery();

  const [searchTerm, setSearchTerm] = useState('');

  const [requestName, setRequestName] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, cancel] = useDebounce(
    () => {
      if (searchTerm !== '') {
        setRequestName(searchTerm);
      }
    },
    1000,
    [searchTerm],
  );
  const [isLoading, setIsLoading] = useState(false);

  const [searchResults, setSearchResults] = useState<TGlobalSearchResult>({
    projects: undefined,
    listings: undefined,
    offers: undefined,
  });

  const navigate = useNavigate();

  const [resultsNr, setResultsNr] = useState<number>(0);

  const [searchBy, setSearchBy] = useState<GlobalSearchOptions>(
    GlobalSearchOptions.PROJECTS,
  );

  const resetSearchResults = () => {
    setSearchResults({
      offers: undefined,
      listings: undefined,
      projects: undefined,
    });
  };

  const handleSelectSearchBy = (searchProp: GlobalSearchOptions) => {
    setSearchBy(searchProp);
  };

  const handleSearchTyping = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const searchText = event.target.value;
    if (searchText !== '') {
      setIsLoading(true);
      setSearchTerm(searchText);
    } else {
      resetSearchResults();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      switch (searchBy) {
        case GlobalSearchOptions.PROJECTS: {
          const response = await triggerSearchByProject(
            nameZod.parse({ name: requestName }),
          ).unwrap();
          setSearchResults({
            offers: searchResults.offers,
            listings: searchResults.listings,
            projects: response,
          });
          setIsLoading(isFetchingProjects);
          break;
        }
        case GlobalSearchOptions.OFFERS: {
          const response = await triggerSearchByOffers(
            nameZod.parse({ name: requestName }),
          ).unwrap();
          setSearchResults({
            offers: response,
            listings: searchResults.listings,
            projects: searchResults.projects,
          });
          setIsLoading(isFetchingOffers);
          break;
        }

        default: {
          const response = await triggerSearchByListing(
            nameZod.parse({ name: requestName }),
          ).unwrap();
          setSearchResults({
            offers: searchResults.offers,
            listings: response,
            projects: searchResults.projects,
          });
          setIsLoading(isFetchingListing);
          break;
        }
      }
    };
    if (requestName !== '') {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestName, setSearchResults]);

  const handleNavigate = (projectName: string) => {
    navigate(`projects/${projectName}`);
    closeModal();
    resetSearchResults();
    setResultsNr(0);
  };

  const getOfferedPrice = (listing: TListing) => {
    const hightestOfferedPrice = getMaxOfferedPrice(listing);
    if (hightestOfferedPrice) {
      return `$${hightestOfferedPrice}`;
    }
    return '-';
  };

  return (
    <Modal open={open} onClose={closeModal}>
      <SearchModalContainer>
        <Box
          className="Search Header"
          sx={{
            display: 'grid',
            gridTemplateColumns: '3fr 120px 40px',
            borderBottom: `1px solid ${palette.secondary.light}`,
            paddingRight: '1rem',
          }}
        >
          <SearchBox
            sx={{
              padding: '1rem !important',
              borderRight: `1px solid ${palette.secondary.light}`,
              borderRadius: 0,
              background: 'transparent',
            }}
          >
            <Svgs.Search style={{ opacity: 0.5, paddingLeft: '0.5rem' }} />
            <SearchBar
              placeholder="Search..."
              sx={{ width: '100% !important', minWidth: 'unset' }}
              onChange={(event) => handleSearchTyping(event)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading && <CircularProgress size={20} />}
                  </InputAdornment>
                ),
              }}
            />
          </SearchBox>
          <SearchSelect
            onChange={(event) =>
              handleSelectSearchBy(event.target.value as GlobalSearchOptions)
            }
            value={searchBy}
            sx={{
              minWidth: '120px',
              paddingLeft: '1.5rem',
              borderRadius: '0 !important',
              background: 'transparent !important',
              borderTop: '0 !important',
              '.MuiSelect-select': {
                padding: '0 !important',
              },
            }}
            MenuProps={SelectMenuProps}
          >
            <MenuItem disableGutters value="Projects">
              Projects
            </MenuItem>
            <MenuItem disableGutters value="Listings">
              Listings
            </MenuItem>
            <MenuItem disableGutters value="Offers">
              Offers
            </MenuItem>
          </SearchSelect>
          <Box sx={{ ...flexCentered, cursor: 'pointer' }} onClick={closeModal}>
            <CloseIcon
              sx={{
                backgroundColor: 'secondary.light',
                padding: '2px',
                opacity: 0.8,
                ':hover': { opacity: 1 },
                borderRadius: '4px',
              }}
            />
          </Box>
        </Box>
        <Box p={3}>
          {resultsNr > 0 ? (
            <Typography
              sx={{
                fontSize: '14px',
                color: 'text.secondary',
              }}
              pb={3}
            >
              There are {resultsNr} results matching your search:
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: '14px',
                color: 'text.secondary',
              }}
              pb={3}
            >
              There are no results yet..
            </Typography>
          )}
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xsm: 'repeat(2, 1fr)', xs: '1fr' },
              gap: '1rem',
            }}
          >
            {searchResults.projects &&
              searchResults.projects.map((project) => (
                <SearchResultCard
                  key={project.id}
                  project={project}
                  handleNavigate={handleNavigate}
                />
              ))}
            {searchResults.listings &&
              searchResults.listings.map((listing) => (
                <SearchResultCard
                  key={listing.id}
                  listing={listing}
                  handleNavigate={handleNavigate}
                  getOfferedPrice={getOfferedPrice}
                />
              ))}
            {searchResults.offers &&
              searchResults.offers.map((offer) => (
                <SearchResultCard
                  key={offer.id}
                  handleNavigate={handleNavigate}
                  offer={offer}
                />
              ))}
          </Box>
        </Box>
      </SearchModalContainer>
    </Modal>
  );
}
export default SearchResponsiveModal;
