/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Box,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Svgs } from '@utils/svgs';
import MenuIcon from '@mui/icons-material/Menu';
import { useMoralis } from 'react-moralis';
import { border1pxPrimaryLight, flexCentered } from '@styles/shared/SCss';
import { useState } from 'react';
import NetworkSelectComp from '@components/header/NetworkSelectComp';
import SearchCom from '@components/header/search/SearchComp';
import HeaderNavbarResponsive from '@components/header/HeaderNavbarResponsive';
import { Link as ReactLink, useLocation } from 'react-router-dom';
import useUser from '@hooks/useUser';
import { ButtonGradientDark } from '@styles/shared/SButtons';
import useActionOrConnect from '@hooks/useActionOrConnect';

interface Props {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  anchorEl: HTMLElement | null;
}

function HeaderNavbar({ handleClick, anchorEl }: Props) {
  const theme = useTheme();
  const actionOrConnect = useActionOrConnect();

  const { enableWeb3, isWeb3Enabled } = useMoralis();

  const { currentUser, sameAccount, isLoading } = useUser();

  const openMenu = Boolean(anchorEl);

  const location = useLocation();

  const downLg = useMediaQuery(theme.breakpoints.down('lg'));

  const themeColors = theme.palette;

  const [openHamburger, setOpenHamburger] = useState<boolean>();

  const styleWhenActiveIcons = {
    position: 'relative',
    '&:before': {
      position: 'absolute',
      content: '""',
      top: 'calc(100% + 17px)',
      left: 0,
      width: '100%',
      height: '1px',
    },
  };

  return (
    <div>
      <Box display="flex" justifyContent="flex-end">
        <MenuIcon
          sx={{
            color: themeColors.text.secondary,
            display: { md: 'block', lg: 'none' },
            marginRight: '1.5rem',
            cursor: 'pointer',
            ':hover': { color: themeColors.primary.main },
          }}
          onClick={() => setOpenHamburger(true)}
        />
      </Box>
      <Box
        sx={{
          display: { xs: 'none', lg: 'flex' },
          justifyContent: {
            xs: 'flex-end',
            sm: 'flex-end',
            md: 'space-between',
          },
          alignItems: 'center',
          minHeight: '100% !important',
          mx: '1.5rem',
        }}
      >
        <Box
          sx={{
            borderRight: border1pxPrimaryLight,
            display: 'flex',
            width: '100%',
            padding: '10px 0',
          }}
        >
          <SearchCom />
          {currentUser && (
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
              {!sameAccount && !isLoading && (
                <Tooltip title="different metamask account detected!">
                  <Typography
                    sx={{
                      color: 'white',
                      p: '0 0.6rem',
                      backgroundColor: 'error.main',
                      borderRadius: '6px',
                      mr: 0.5,
                      fontWeight: '700',
                    }}
                  >
                    !
                  </Typography>
                </Tooltip>
              )}
              <Link
                component={ReactLink}
                to="/account"
                aria-label="account"
                sx={{
                  display: 'flex',
                  marginRight: '16px',
                  color:
                    location.pathname === '/account'
                      ? theme.palette.text.primary
                      : theme.palette.primary.dark,
                  ...styleWhenActiveIcons,
                  '&::before': {
                    backgroundColor:
                      location.pathname === '/account'
                        ? theme.palette.text.primary
                        : 'transparent',
                  },
                  '&:hover': {
                    color: theme.palette.text.primary,
                  },
                }}
              >
                <Svgs.Account
                  color="inherit"
                  width={20}
                  height={20}
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Link>
              <Box
                onClick={handleClick}
                mr="16px"
                sx={{
                  ...flexCentered,
                  ...styleWhenActiveIcons,
                  color: openMenu
                    ? theme.palette.text.primary
                    : theme.palette.primary.dark,
                  '&::before': {
                    backgroundColor: openMenu
                      ? theme.palette.text.primary
                      : 'transparent',
                  },
                  '&:hover': {
                    color: theme.palette.text.primary,
                  },
                }}
              >
                <Svgs.Portfolio
                  color="inherit"
                  width={20}
                  height={20}
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={{ ...flexCentered }}>
          {!isWeb3Enabled && currentUser && (
            <ButtonGradientDark
              sx={{ ml: '1rem', whiteSpace: 'nowrap' }}
              onClick={() => enableWeb3()}
            >
              Connect to Web3
            </ButtonGradientDark>
          )}

          {currentUser ? (
            <NetworkSelectComp />
          ) : (
            <Box sx={{ width: '100%', ml: '1.5rem' }}>
              <ButtonGradientDark onClick={() => actionOrConnect()}>
                Login
              </ButtonGradientDark>
            </Box>
          )}
        </Box>
      </Box>
      {downLg && (
        <HeaderNavbarResponsive
          openHamburger={openHamburger}
          setOpenHamburger={setOpenHamburger}
        />
      )}
    </div>
  );
}
export default HeaderNavbar;
