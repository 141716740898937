import { ListingType } from '@constants/CListings';
import { Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid';
import { DataGridCustomStyles, gridRow } from '@styles/shared/SDataGrid';
import { TListing } from '@typescript/models/Listing.model';
import { formatNr, sliceWalletAddress } from '@utils/functions';
import moment from 'moment';
import { memo } from 'react';

const listingsColumns = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  action: (listings: TListing) => void,
): GridColumns<TListing> => [
  {
    ...gridRow,
    field: 'ID',
    headerName: 'ID',
    valueGetter: ({ row }) => row.id,
  },
  {
    ...gridRow,
    field: 'Project',
    headerName: 'Project',
    valueGetter: ({ row }) => row.project.name,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.project.id}>
        <Typography>{row.project.name}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'User',
    headerName: 'User',
    valueGetter: ({ row }) => row.user.ethAddress,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.user.ethAddress}>
        <Typography>{sliceWalletAddress(row.user.ethAddress)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Token Price',
    headerName: 'Token Price',
    valueGetter: ({ row }) => row.tokenPrice,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.tokenPrice}>
        <Typography>{formatNr(row.tokenPrice, true, 2)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Amount',
    headerName: 'Amount',
    valueGetter: ({ row }) => row.amount,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.amount}>
        <Typography>{formatNr(row.amount, undefined, 2)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Total Price',
    headerName: 'Total Price',
    valueGetter: ({ row }) => row.price,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title={row.price}>
        <Typography>{formatNr(row.price, true, 2)}</Typography>
      </Tooltip>
    ),
  },
  {
    ...gridRow,
    field: 'Type',
    headerName: 'Type',
    valueGetter: ({ row }) => (row.type === ListingType.BUY ? 'WTB' : 'WTS'),
  },
  {
    ...gridRow,
    field: 'Negotiable',
    headerName: 'Negotiable',
    valueGetter: ({ row }) => String(row.negotiable).toUpperCase(),
  },
  {
    ...gridRow,
    field: 'Transaction',
    headerName: 'Transaction',
    valueGetter: ({ row }) => row.transaction?.id || '-',
  },
  {
    ...gridRow,
    field: 'In Progress',
    headerName: 'In Progress',
    valueGetter: ({ row }) => String(row.transactionInProgress).toUpperCase(),
  },
  {
    ...gridRow,
    field: 'Deleted',
    headerName: 'Deleted',
    valueGetter: ({ row }) => String(row.isDeleted).toUpperCase(),
  },
  {
    ...gridRow,
    field: 'Expired',
    headerName: 'Expired',
    valueGetter: ({ row }) => String(row.isExpired).toUpperCase(),
  },
  {
    ...gridRow,
    field: 'Expire In',
    headerName: 'Expired In',
    valueGetter: ({ row }) => `${row.expireIn} DAYS`,
  },
  {
    ...gridRow,
    field: 'Expire At',
    headerName: 'Expired At',
    valueGetter: ({ row }) => moment(row.expireAt).format('YYYY-MM-DD HH:mm'),
  },
];

interface Props {
  action: (listing: TListing) => void;
  rows: TListing[];
}

function AdminUserListingsTable({ action, rows }: Props) {
  return (
    <DataGrid
      sx={DataGridCustomStyles}
      rows={rows}
      columns={listingsColumns(action)}
      disableSelectionOnClick
      autoHeight
      pageSize={30}
      rowsPerPageOptions={[30]}
      disableColumnSelector
      disableDensitySelector
      getRowId={(row) => row.id}
      components={{
        Toolbar: GridToolbar,
      }}
    />
  );
}

export default memo(AdminUserListingsTable);
