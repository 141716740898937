import {
  tradeTypeCell,
  totalCostCell,
  tokenAmountCell,
  WalletCell,
  TimeCell,
  confirmationCell,
} from '@components/tables-columns/TableCell';
import { Box, Typography } from '@mui/material';
import useStyles from '@hooks/useStyles';
import { TTransaction } from '@typescript/models/TradeTransaction.model';
import { useNavigate } from 'react-router-dom';

interface Props {
  transaction: TTransaction;
}

function ResponsiveListingCard({ transaction }: Props) {
  const { border1pxSecondaryLight } = useStyles();
  const navigate = useNavigate();
  return (
    <Box
      key={transaction.id}
      sx={{
        borderRadius: '6px',
        border: border1pxSecondaryLight,
      }}
    >
      <Box
        sx={{
          borderBottom: border1pxSecondaryLight,
          borderRadius: '4px 4px 0 0',
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <img
            src={transaction.project.image}
            style={{ borderRadius: '6px', width: 48, height: 48 }}
            alt=""
          />
          <Typography
            sx={{
              pl: 1,
              fontWeight: 400,
              fontSize: '17px',
              textDecoration: 'underline',
              cursor: 'pointer',
              ':hover': {
                textDecoration: 'none',
              },
            }}
            onClick={() => navigate(`/projects/${transaction.project.slug}`)}
          >
            {transaction.project.name}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: 'grid', md: 'flex' },
          gridTemplateColumns: { xs: '1fr 1fr' },
        }}
      >
        <Box
          sx={{
            p: 3,
            borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
            borderRight: border1pxSecondaryLight,
            flex: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              opacity: 0.5,
              mb: 1,
            }}
          >
            Event
          </Typography>
          {tradeTypeCell(transaction.tradeType).renderCell}
        </Box>
        <Box
          sx={{
            p: 3,
            borderRight: { xs: 'none', md: border1pxSecondaryLight },
            borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
            flex: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              opacity: 0.5,
              mb: 1,
            }}
          >
            Total Cost
          </Typography>
          {totalCostCell(transaction.value).renderCell}
        </Box>
        <Box
          sx={{
            p: 3,
            borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
            borderRight: border1pxSecondaryLight,
            flex: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              opacity: 0.5,
              mb: 1,
            }}
          >
            Token Amount
          </Typography>
          {tokenAmountCell(transaction.tokenAmount).renderCell}
        </Box>
        <Box
          sx={{
            p: 3,
            borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
            borderRight: { xs: 'none', md: border1pxSecondaryLight },
            flex: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              opacity: 0.5,
              mb: 1,
            }}
          >
            From
          </Typography>
          <WalletCell wallet={transaction.from.ethAddress} />
        </Box>
        <Box
          sx={{
            p: 3,
            borderRight: border1pxSecondaryLight,
            flex: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              opacity: 0.5,
              mb: 1,
            }}
          >
            To
          </Typography>
          <WalletCell wallet={transaction.to.ethAddress} />
        </Box>
        <Box
          sx={{
            p: 3,
            flex: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              opacity: 0.5,
              mb: 1,
            }}
          >
            Time
          </Typography>
          <TimeCell hash={transaction.hash} createdAt={transaction.createdAt} />
        </Box>
        <Box
          sx={{
            p: 3,
            flex: 2,
            borderTop: { xs: border1pxSecondaryLight, md: 'none' },
            borderRight: { xs: border1pxSecondaryLight, md: 'none' },
            borderLeft: { xs: 'none', md: border1pxSecondaryLight },
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              opacity: 0.5,
              mb: 1,
            }}
          >
            Confirmed
          </Typography>
          {confirmationCell(transaction.confirmed).renderCell}
        </Box>
      </Box>
    </Box>
  );
}

export default ResponsiveListingCard;
