import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { DataGridSeeMoreStyles } from '@styles/shared/SDataGrid';
import { SeeMoreButton } from '@styles/portfolio/SPortfolio';
import { Svgs } from '@utils/svgs';
import DataWrapper from '@components/shared/DataWrapper';
import { paginationZod } from '@typescript/dtos/shared/pagination.dto';
import { useGetMyTradeTxByTypeQuery } from '@store/api/transactionApi';
import { baseTradeTxColumns } from '@components/tables-columns/TradeTxColumns';
import { memo } from 'react';
import DataGridWrapper from '@components/shared/DataGridWrapper';

function DashboardTransactionsTable() {
  const navigate = useNavigate();
  const {
    data: { resource: myTradeTx } = { resource: [] },
    isLoading,
    isFetching,
  } = useGetMyTradeTxByTypeQuery(
    paginationZod.parse({
      page: 1,
      pageSize: 4,
    }),
  );

  return (
    <DataWrapper
      isLoading={isLoading}
      data={myTradeTx}
      defaultOptions={{
        icon: Svgs.IconTransactions,
        text: 'There are no transactions to display at this moment',
      }}
      isLoadingOptions={{ dimension: 'medium' }}
    >
      <DataGridWrapper isFetching={isFetching}>
        <DataGrid
          sx={DataGridSeeMoreStyles}
          rows={myTradeTx}
          columns={baseTradeTxColumns}
          autoHeight
          pageSize={4}
          hideFooter
          disableColumnMenu
        />
      </DataGridWrapper>
      <SeeMoreButton onClick={() => navigate('/activity-stream')} fullWidth>
        See More
      </SeeMoreButton>
    </DataWrapper>
  );
}

export default memo(DashboardTransactionsTable);
