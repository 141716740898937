import { Menu, Box, Typography, useTheme } from '@mui/material';
import colors from '@theme/customColors';
import SearchModalData from '@components/header/search/SearchModalData';
import { GlobalSearchOptions } from '@constants/CSearch';
import { TGlobalSearchResult } from '@typescript/TSearch';

interface Props {
  anchorEl: HTMLElement | null;
  openResults: boolean;
  handleClose: () => void;
  searchBy: GlobalSearchOptions;
  searchResults: TGlobalSearchResult;
  isLoading: boolean;
}

function SearchBarModal({
  anchorEl,
  openResults,
  handleClose,
  searchBy,
  searchResults,
  isLoading,
}: Props) {
  const modalWidth =
    searchBy === GlobalSearchOptions.PROJECTS ? '483px' : '620px';

  const theme = useTheme();

  const PaperPropsStyle = {
    top: '45px !important',
    left: '216px !important',
    borderRadius: '0 0 6px 6px',
    borderTop: `1px solid ${theme.palette.secondary.light}!important`,
    width: modalWidth,
    backgroundColor: colors.riverStyxDarkBlue,
    '& .MuiList-root': {
      padding: '5px',

      '& .MuiBox-root': {
        height: 'auto',
        background: 'transparent',
      },
    },
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={openResults}
      disableAutoFocus
      onClose={handleClose}
      id="search-modal"
      transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      PaperProps={{ sx: PaperPropsStyle }}
    >
      <Box
        sx={{
          display: 'grid',
          backgroundColor: colors.riverStyxDarkBlue,
        }}
      >
        {searchResults.projects?.length ||
        searchResults.listings ||
        searchResults.offers ? (
          <SearchModalData data={searchResults} close={handleClose} />
        ) : (
          <Box
            sx={{ padding: '0.5rem', display: isLoading ? 'none' : 'block' }}
          >
            <Typography
              variant="h5"
              sx={{ fontSize: '15px', color: 'primary.main' }}
            >
              No results found..
            </Typography>
          </Box>
        )}
      </Box>
    </Menu>
  );
}
export default SearchBarModal;
