import { Box, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { LinkStyled } from '@styles/shared/SLinks';
import useUtils from '@hooks/useUtils';
import { TTransactionInvestment } from '@typescript/models/InvestmentTransaction.model';
import useStyles from '@hooks/useStyles';
import { BuyGradientDark } from '@styles/shared/SButtons';
import CardWrapper from '@components/shared/CardWrapper';
import {
  WalletCell,
  confirmationCell,
} from '@components/tables-columns/TableCell';

interface Props {
  transactionInvestment: TTransactionInvestment[];
  actionSeeMore: () => void;
  count: number;
  isLoading: boolean;
}
function InvestmentTableResponsive({
  transactionInvestment,
  actionSeeMore,
  count,
  isLoading,
}: Props) {
  const { serverNetwork } = useUtils();

  const navigate = useNavigate();

  const { border1pxSecondaryLight } = useStyles();

  const getTimeValue = (row: TTransactionInvestment) => {
    if (row.id) {
      const { createdAt } = row;
      const networkLink =
        serverNetwork === 'bsc'
          ? 'https://bscscan.com/tx/'
          : 'https://testnet.bscscan.com/tx/';

      const link = `${networkLink}${row.transactionHash}`;
      return (
        <LinkStyled
          target="_blank"
          href={link}
          aria-label="transaction time"
          sx={{ marginRight: '0.3rem' }}
        >
          {moment(createdAt).fromNow()}
        </LinkStyled>
      );
    }
    return null;
  };

  return (
    <Box sx={{ display: 'grid', gap: '0.7rem', pb: '74px' }}>
      {transactionInvestment.map((transaction) => (
        <CardWrapper isFetching={isLoading} key={transaction.id}>
          <Box
            key={transaction.id}
            sx={{
              width: '100%',
              borderRadius: '6px',
              border: border1pxSecondaryLight,
              backgroundColor: 'secondary.main',
              mb: '1rem',
            }}
          >
            <Box
              sx={{
                borderBottom: border1pxSecondaryLight,
                borderRadius: '4px 4px 0 0',
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={transaction.project.image}
                  style={{ borderRadius: '6px', width: 48, height: 48 }}
                  alt=""
                />
                <Typography
                  sx={{
                    pl: 1,
                    fontWeight: 400,
                    fontSize: '17px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    ':hover': {
                      textDecoration: 'none',
                    },
                  }}
                  onClick={() =>
                    navigate(`/projects/${transaction.project.slug}`)
                  }
                >
                  {transaction.project.name}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
                >
                  Time
                </Typography>
                <Typography mt={1}>{getTimeValue(transaction)}</Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
              }}
            >
              <Box
                sx={{
                  p: 3,
                  borderBottom: border1pxSecondaryLight,
                  borderRight: border1pxSecondaryLight,
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
                >
                  Investment Amount
                </Typography>
                <Typography mt={1}>${transaction.investmentAmount}</Typography>
              </Box>

              <Box
                sx={{
                  p: 3,
                  borderBottom: border1pxSecondaryLight,
                  borderRight: border1pxSecondaryLight,
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
                >
                  Token Amount
                </Typography>
                <Typography mt={1}>{transaction.totalTokens}</Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                  borderBottom: border1pxSecondaryLight,
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
                >
                  Fee
                </Typography>
                <Typography mt={1}>{transaction.feeInPercentage}%</Typography>
              </Box>
              <Box
                sx={{
                  p: 3,
                  borderRight: border1pxSecondaryLight,
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
                >
                  From
                </Typography>
                <WalletCell wallet={transaction.from.ethAddress} />
              </Box>
              <Box
                sx={{
                  p: 3,
                  borderRight: border1pxSecondaryLight,
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
                >
                  To
                </Typography>
                <WalletCell wallet={transaction.to} />
              </Box>
              <Box
                sx={{
                  p: 3,
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
                >
                  Confirmed
                </Typography>
                {confirmationCell(transaction.confirmed).renderCell}
              </Box>
            </Box>
          </Box>
        </CardWrapper>
      ))}
      {isLoading ? (
        <Box
          sx={{
            maxWidth: { xs: '375px', md: 'unset' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            maxWidth: { xs: '375px', md: 'unset' },
            display: count === transactionInvestment.length ? 'none' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <BuyGradientDark onClick={() => actionSeeMore()}>
            See More
          </BuyGradientDark>
        </Box>
      )}
    </Box>
  );
}
export default InvestmentTableResponsive;
