import { Box, Typography } from '@mui/material';
import useStyles from '@hooks/useStyles';
import { Svgs } from '@utils/svgs';
import { CardBox } from '@styles/help-center/SHelpCenter';

interface Props {
  href: string;
  title: string;
  description: string;
}

function LinkBox({ href, title, description }: Props) {
  const { border1pxSecondaryLight } = useStyles();

  return (
    <a
      href={href}
      target="_blank"
      style={{ color: 'unset', textDecoration: 'unset' }}
      rel="noreferrer"
    >
      <CardBox>
        <Box padding="0.7rem">
          <Typography sx={{ fontSize: '13px', fontWeight: 700, mb: '0.3rem' }}>
            {title}
          </Typography>
          <Typography
            sx={{ fontSize: '13px', fontWeight: 700, opacity: '0.5' }}
          >
            {description}
          </Typography>
        </Box>
        <Box
          sx={{
            borderLeft: border1pxSecondaryLight,
            padding: '0.5rem 1.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Svgs.ArrowRightAltRounded />
        </Box>
      </CardBox>
    </a>
  );
}

export default LinkBox;
