import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TAllocation } from '@typescript/models/Allocation.model';
import DefaultPage from '@pages/Default';
import { SeeMoreButton } from '@styles/portfolio/SPortfolio';
import useStyles from '@hooks/useStyles';
import { Svgs } from '@utils/svgs';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import DataWrapper from '@components/shared/DataWrapper';
import {
  currentTokenPriceCell,
  performanceCell,
  projectCell,
  tokenAmountCell,
  tokenValueCell,
} from '@components/tables-columns/TableCell';
import { displayAllocationActions } from '@components/tables-columns/AllocationsColumns';

interface Props {
  myAllocations?: TAllocation[];
  handleSellNowBtn: (allocation: TAllocation) => void;
  isFetchingMyAllocations: boolean;
}

function DashboardPortfolioResponsive({
  myAllocations,
  handleSellNowBtn,
  isFetchingMyAllocations,
}: Props) {
  const navigate = useNavigate();

  const { border1pxSecondaryLight } = useStyles();

  if (isFetchingMyAllocations) {
    return <IsLoadingCom dimension="medium" />;
  }
  if (!myAllocations) {
    return (
      <DefaultPage
        icon={Svgs.EmptyProjects}
        text="There are no projects to display at this moment"
      />
    );
  }

  return (
    <DataWrapper
      isLoading={isFetchingMyAllocations}
      data={myAllocations}
      defaultOptions={{
        icon: Svgs.EmptyProjects,
        text: 'There are no projects to display at this moment',
      }}
      isLoadingOptions={{ dimension: 'medium' }}
    >
      <Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', md: '1fr' },
            gap: '0.7rem',
          }}
        />
        {myAllocations.map((allocation) => (
          <Box
            key={allocation.id}
            sx={{
              mb: '1rem',
              borderRadius: '6px',
              border: border1pxSecondaryLight,
              backgroundColor: 'secondary.main',
            }}
          >
            <Box
              sx={{
                borderBottom: border1pxSecondaryLight,
                borderRadius: '4px 4px 0 0',
                py: 2,
                px: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {projectCell(allocation.project).renderCell}
              <Box>
                {displayAllocationActions(allocation, handleSellNowBtn)}
              </Box>
            </Box>
            <Box
              sx={{
                display: { md: 'flex' },
                flexWrap: 'wrap',
                flexDirection: { ms: 'column' },
              }}
            >
              <Box sx={{ display: 'flex', flex: 6 }}>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: { md: border1pxSecondaryLight },
                    flex: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: 'text.shadedWhite',
                      mb: 1,
                    }}
                  >
                    Balance
                  </Typography>
                  {tokenAmountCell(allocation.lockedTokens).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: { md: border1pxSecondaryLight },
                    flex: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: 'text.shadedWhite',
                      mb: 1,
                    }}
                  >
                    Current Token Price
                  </Typography>
                  {
                    currentTokenPriceCell(allocation.project.currentPrice)
                      .renderCell
                  }
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                    borderRight: { md: border1pxSecondaryLight },
                    flex: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: 'text.shadedWhite',
                      mb: 1,
                    }}
                  >
                    Value Locked
                  </Typography>
                  {tokenValueCell(allocation.lockedValue).renderCell}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flex: 6 }}>
                <Box
                  sx={{
                    p: 2,
                    borderRight: { xs: 'none', md: border1pxSecondaryLight },
                    flex: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: 'text.shadedWhite',
                      mb: 1,
                    }}
                  >
                    Value Unlocked
                  </Typography>
                  {tokenValueCell(allocation.unlockedValue).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    flex: 4.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: 'text.shadedWhite',
                      mb: 1,
                    }}
                  >
                    Performance
                  </Typography>
                  {performanceCell(allocation.project.performance).renderCell}
                </Box>
              </Box>
            </Box>
          </Box>
        ))}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: { xs: 0, lg: 2 },
          }}
        >
          <SeeMoreButton
            sx={{
              borderTop: border1pxSecondaryLight,
              borderRadius: '6px !important',
              width: '130px !important',
              fontSize: '13px',
            }}
            onClick={() => navigate('/portfolio')}
            fullWidth
          >
            See More
          </SeeMoreButton>
        </Box>
      </Box>
    </DataWrapper>
  );
}

export default DashboardPortfolioResponsive;
