import { Box, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TGlobalSearchResult } from '@typescript/TSearch';
import colors from '@theme/customColors';
import { flexCentered } from '@styles/shared/SCss';
import { Svgs } from '@utils/svgs';
import { DataGrid } from '@mui/x-data-grid';
import { TListing } from '@typescript/models/Listing.model';
import { TOffer } from '@typescript/models/Offer.model';
import { DataGridCustomStyles } from '@styles/shared/SDataGrid';
import useStyles from '@hooks/useStyles';
import { getListingsColumnsWithTradeActions } from '@components/tables-columns/ListingsColumns';
import { getOffersColumnsWithActions } from '@components/tables-columns/OffersColumns';
import { Coin } from '@constants/CCoin';
import { ListingAction, OfferAction } from '@constants/CDataActions';
import { TradeModalStep } from '@constants/CTransfer';
import useListings from '@hooks/useListings';
import useNotification from '@hooks/useNotification';
import useOffers from '@hooks/useOffers';
import useUser from '@hooks/useUser';
import { TDataActions } from '@typescript/TDataActions';
import { sellWTBZod } from '@typescript/dtos/listing/createListing.dto';
import {
  CreateOfferDTO,
  createOfferZod,
} from '@typescript/dtos/offer/createOffer.dto';
import { useState, useCallback } from 'react';
import DeleteListingModal from '@components/modals/listings/DeleteListingModal';
import SellWTBModal from '@components/modals/listings/SellWTBModal';
import MakeOfferModal from '@components/modals/offers/MakeOfferModal';
import WtbTxModals from '@components/modals/transactions/WtbTxModals';
import WtsTxModals from '@components/modals/transactions/WtsTxModals';
import { idZod } from '@typescript/dtos/shared/id.dto';
import OfferTxModals from '@components/modals/transactions/OfferTxModals';
import ConfirmationOfferModal from '@components/modals/offers/ConfirmationOfferModal';
import DeleteOfferModal from '@components/modals/offers/DeleteOfferModal';
import ModifyOfferModal from '@components/modals/offers/ModifyOfferModal';
import { OfferStatus } from '@constants/COffer';
import useActionOrConnect from '@hooks/useActionOrConnect';

const textStyle = {
  fontSize: '15px',
  color: 'primary.main',
};

const arrowStyle = {
  width: 24,
  height: 24,
  ...flexCentered,
  borderRadius: '6px',
  backgroundColor: 'rgba(141, 153, 174, 0.15)',
};

interface Props {
  data: TGlobalSearchResult;
  close: () => void;
}
function SearchModalData({ data, close }: Props) {
  const navigate = useNavigate();
  const handleActionOrConnect = useActionOrConnect();

  const theme = useTheme();

  const { border1pxSecondaryLight } = useStyles();

  const { createOffer } = useOffers();

  const { currentUser, insufficientFunds } = useUser();

  const { notifySuccess, notifyError } = useNotification();

  const { deleteListing, sellWTB } = useListings();

  const [currentListing, setCurrentListing] = useState<TListing>();

  const [tradeModalStep, setTradeModalStep] = useState<TradeModalStep>(
    TradeModalStep.BUY,
  );

  const [action, setAction] = useState<TDataActions>({
    listingAction: null,
    offerAction: null,
  });

  const [openActionModal, setOpenActionModal] = useState<boolean>(false);

  const [actionOffer, setActionOffer] = useState<OfferAction | null>(null);

  const [currentOffer, setCurrentOffer] = useState<TOffer | null>(null);

  const [actionModal, setActionModal] = useState<boolean>(false);

  const closeActionModal = () => {
    setOpenActionModal(false);
    close();
  };

  const sellWTBListing = async (acceptedCoin: Coin) => {
    try {
      if (!currentListing) {
        throw Error('No listing found');
      }
      await sellWTB(sellWTBZod.parse({ id: currentListing.id, acceptedCoin }));
      notifySuccess('Sell confirmation sent!');
    } catch (error) {
      notifyError('Sell confirmation failed!');
      throw error;
    }

    closeActionModal();
  };

  const makeOffer = async (offer: CreateOfferDTO) => {
    if (currentListing && currentUser) {
      try {
        await createOffer(
          createOfferZod.parse({
            offeredPrice: offer.offeredPrice,
            offeredTokenPrice: offer.offeredTokenPrice,
            offerCoin: offer.offerCoin,
            listingId: offer.listingId,
          }),
        ).unwrap();
        notifySuccess('Offer created!');
      } catch (error) {
        notifyError('Offer creation failed!');
        throw error;
      }
    }
    closeActionModal();
  };

  const handleListingActions = useCallback(
    (listing: TListing, listingAction: ListingAction) => {
      setCurrentListing(listing);
      setAction({ offerAction: null, listingAction });
      setOpenActionModal(true);
      setTradeModalStep(TradeModalStep.BUY);
    },
    [],
  );

  const changeAction = (newOffer: TOffer, newAction: OfferAction) => {
    setCurrentOffer(newOffer);
    setActionOffer(newAction);
    setActionModal(true);
    setTradeModalStep(TradeModalStep.BUY);
  };

  const tableStyle = {
    ...DataGridCustomStyles,
    height: 330,
    backgroundColor: colors.riverStyxDarkBlue,
    '& .MuiDataGrid-virtualScroller': {
      marginTop: '65px !important',
      paddingBottom: '1rem !important',
    },
    '& .MuiDataGrid-columnHeaders': {
      opacity: 0.5,
      borderBottom: border1pxSecondaryLight,
    },
    '& .MuiDataGrid-row': {
      backgroundColor: colors.riverStyxDarkBlue,
      cursor: 'pointer',
      ':hover': {
        backgroundColor: '#151624',
      },
    },
  };

  const containerStyle = {
    display: 'flex',
    border: border1pxSecondaryLight,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
    borderRadius: '6px',
    ':hover': {
      backgroundColor: theme.palette.primary.contrastText,
      cursor: 'pointer',
    },
  };

  const handleOnClick = (projectSlug: string) => {
    close();
    navigate(`/projects/${projectSlug}`);
  };

  const dynamicHeight = (length: number) => {
    let height = 0;
    if (length > 5) {
      height = 5 * 63; // 63 pixels for each box
    } else {
      height = length * 63;
    }
    return height;
  };

  const listingModals = () => {
    if (currentListing) {
      switch (action.listingAction) {
        case ListingAction.BUY_WTS:
          return (
            <WtsTxModals
              step={tradeModalStep}
              changeStep={(newStep: TradeModalStep) =>
                setTradeModalStep(newStep)
              }
              listing={currentListing}
            />
          );

        case ListingAction.BUY_WTB:
          return (
            <WtbTxModals
              step={tradeModalStep}
              changeStep={(newStep: TradeModalStep) =>
                setTradeModalStep(newStep)
              }
              listing={currentListing}
            />
          );

        case ListingAction.SELL_WTB:
          return (
            <SellWTBModal
              listing={currentListing}
              openModal={openActionModal}
              closeModal={closeActionModal}
              sellWTBListing={sellWTBListing}
            />
          );
        case ListingAction.MAKE_OFFER:
          return (
            <MakeOfferModal
              openModal={openActionModal}
              closeModal={closeActionModal}
              listing={currentListing}
              makeOffer={makeOffer}
            />
          );

        case ListingAction.DELETE:
          return (
            <DeleteListingModal
              openModal={openActionModal}
              closeModal={closeActionModal}
              listing={currentListing}
              deleteListing={async (payload) => {
                await deleteListing(idZod.parse(payload));
              }}
            />
          );
        default:
          return null;
      }
    }
    return null;
  };

  const offerModals = () => {
    if (currentOffer) {
      switch (actionOffer) {
        case OfferAction.MAKE_OFFER_TRANSACTION:
          return (
            <OfferTxModals
              step={tradeModalStep}
              changeStep={(newStep: TradeModalStep) =>
                setTradeModalStep(newStep)
              }
              offer={currentOffer}
            />
          );
        case OfferAction.DELETE:
          return (
            <DeleteOfferModal
              openModal={actionModal}
              closeModal={closeActionModal}
              currentOffer={currentOffer}
            />
          );
        case OfferAction.EDIT:
          return (
            <ModifyOfferModal
              openModal={actionModal}
              closeModal={closeActionModal}
              offer={currentOffer}
            />
          );
        case OfferAction.ACCEPT_OFFER:
          return (
            <ConfirmationOfferModal
              openModal={actionModal}
              closeModal={closeActionModal}
              currentOffer={currentOffer}
              newStatus={OfferStatus.ACCEPTED}
            />
          );
        case OfferAction.REJECT_OFFER:
          return (
            <ConfirmationOfferModal
              openModal={actionModal}
              closeModal={closeActionModal}
              currentOffer={currentOffer}
              newStatus={OfferStatus.REJECTED}
            />
          );

        default:
          return null;
      }
    }
    return null;
  };

  return (
    <>
      <Box
        sx={{
          overflowY: 'auto',
          height: dynamicHeight(data.projects?.length || 0),
        }}
      >
        {data.projects &&
          data.projects.map((project, index) => (
            <Box
              sx={{ ...containerStyle, marginTop: index !== 0 ? '0.5rem' : 0 }}
              key={project.id}
              onClick={() => handleOnClick(project.slug)}
            >
              <Box sx={{ ...flexCentered, gap: '0.5rem' }}>
                <Typography variant="h5" sx={textStyle}>
                  {project.name}
                </Typography>
                <Typography variant="h5" sx={{ ...textStyle, opacity: 0.5 }}>
                  {project.symbol}
                </Typography>
              </Box>
              <Box sx={arrowStyle}>
                <Svgs.ArrowRight style={{ opacity: 0.5 }} />
              </Box>
            </Box>
          ))}
      </Box>
      {data.listings && (
        <DataGrid
          sx={tableStyle}
          disableSelectionOnClick
          rows={data.listings}
          getRowId={(row) => row.id}
          columns={getListingsColumnsWithTradeActions(
            handleListingActions,
            handleActionOrConnect,
            currentUser,
          )}
          disableColumnMenu
          hideFooter
        />
      )}
      {data.offers && (
        <DataGrid
          sx={tableStyle}
          disableSelectionOnClick
          rows={data.offers}
          columns={getOffersColumnsWithActions(
            changeAction,
            insufficientFunds,
            currentUser,
          )}
          disableColumnMenu
          hideFooter
        />
      )}
      {currentListing && listingModals()}
      {currentOffer && offerModals()}
    </>
  );
}

export default SearchModalData;
