import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from '@theme/theme';
import React from 'react';

interface Props {
  children: React.ReactElement;
}

function MuiInitialization({ children }: Props) {
  return (
    <ThemeProvider theme={theme('dark')}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

export default MuiInitialization;
