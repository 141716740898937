import { Box, Typography, useTheme, Button } from '@mui/material';

import { toRem } from '@utils/functions';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import { paginationZod } from '@typescript/dtos/shared/pagination.dto';
import { useGetMyInactiveListingsQuery } from '@store/api/listingApi';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function DashboardInactiveNotification() {
  const theme = useTheme();

  const {
    data: { count: inactiveListingsCount } = {
      count: 0,
    },
  } = useGetMyInactiveListingsQuery(
    paginationZod.parse({ page: 1, pageSize: 1 }),
  );

  const [isNotificationRead, setIsNotificationRead] = useState<boolean>(
    Boolean(localStorage.getItem('notificationRead')) ||
      inactiveListingsCount === 0,
  );

  const handleDiscardNotification = () => {
    localStorage.setItem('notificationRead', 'true');
    setIsNotificationRead(true);
  };

  useEffect(() => {
    setIsNotificationRead(
      Boolean(localStorage.getItem('notificationRead')) ||
        inactiveListingsCount === 0,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inactiveListingsCount]);

  if (inactiveListingsCount === 0 && isNotificationRead) {
    return null;
  }

  return (
    <Box
      sx={{
        minHeight: {
          xs: isNotificationRead ? '0px' : '128px',
          md: isNotificationRead ? '0px' : '66px',
        },
        transition: `min-height ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
      }}
    >
      <Box
        sx={{
          padding: toRem(12),
          backgroundColor: 'warning.main',
          borderRadius: toRem(8),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'ceter',
          fontSize: toRem(16),

          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: toRem(14),
          },
        }}
        mt={3}
        mx={2}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.background.default,
          }}
        >
          <Typography>
            You have{' '}
            {inactiveListingsCount === 99 ? '99+' : inactiveListingsCount}{' '}
            inactive {pluralize('listings', inactiveListingsCount)}. Visit your
            inactive {pluralize('listings', inactiveListingsCount)} to claim
            back your funds or extend the expiry dates.
          </Typography>
        </Box>

        <Box
          sx={{
            [theme.breakpoints.down('md')]: {
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
            },
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              flexShrink: 0,
              alignItems: 'center',
            },
          }}
        >
          <Button
            sx={{
              backgroundColor: 'transparent',
              color: theme.palette.background.default,
              flexShrink: '0',
              '&:hover': {
                color: theme.palette.text.primary,
              },
            }}
            onClick={handleDiscardNotification}
            size="small"
            variant="outlined"
          >
            Discard
            <CloseIcon fontSize="small" />
          </Button>

          <Link to="/my-listings/inactive">
            <Button
              LinkComponent="span"
              sx={{ marginLeft: { xs: '0', md: toRem(8) } }}
              size="small"
              variant="outlined"
              href="/my-listings/inactive"
            >
              My Inactive Listings{' '}
              <ArrowForwardIcon
                sx={{ marginLeft: toRem(4) }}
                fontSize="small"
              />
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardInactiveNotification;
