import { Box, Typography } from '@mui/material';

import { useState } from 'react';
import VerificationInput from 'react-verification-input';
import useStyles from '@hooks/useStyles';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import ModalCom from '@components/modals/ModalCom';
import CloseIconCom from '@components/shared/CloseIconCom';
import useUser from '@hooks/useUser';
import useNotification from '@hooks/useNotification';

interface Props {
  open: boolean;
  closeModal: () => void;
}

function EnterCodeModal({ open, closeModal }: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const { border1pxSecondaryLight } = useStyles();

  const { verifyEmailCode } = useUser();

  const { notifySuccess } = useNotification();

  const [error, setError] = useState<'' | 'The code you entered is invalid.'>(
    '',
  );

  const onClose = () => {
    closeModal();
    setError('');
  };

  const handleOnComplete = async (code: string) => {
    try {
      setLoading(true);
      const response = await verifyEmailCode(+code);
      if (response === 'success') {
        notifySuccess('Email Verified!');
        onClose();
      } else {
        setError('The code you entered is invalid.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalCom open={open} onClose={onClose} containerSx={{ minWidth: '490px' }}>
      {loading ? (
        <IsLoadingCom dimension="medium" />
      ) : (
        <Box>
          <Box
            className="Header"
            sx={{
              px: 3,
              pb: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: border1pxSecondaryLight,
            }}
          >
            <Typography
              sx={{
                fontSize: '24px',
                fontWeight: 500,
              }}
            >
              Email Confirmation
            </Typography>
            <CloseIconCom onClick={onClose} />
          </Box>
          <Box className="Body" sx={{ px: 3 }}>
            <Typography
              sx={{
                textAlign: 'center',
                maxWidth: '350px',
                marginX: 'auto',
                fontSize: '20px',
                fontWeight: 400,
                py: 3,
              }}
            >
              We’ve sent you a verification code in your email, please enter it
              below
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', pb: 2 }}>
              <VerificationInput
                autoFocus
                classNames={{
                  container: 'enter-code-container',
                  character: 'enter-code-character',
                  characterSelected: 'enter-code-character--selected',
                }}
                onComplete={(code) => handleOnComplete(code)}
              />
            </Box>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '14px',
                color: 'error.main',
                height: 21,
              }}
            >
              {error}
            </Typography>
            {/* <Button variant="outlined" fullWidth sx={{ mt: 3 }}>
                Confirm My Email Address
              </Button> */}
          </Box>
        </Box>
      )}
    </ModalCom>
  );
}

export default EnterCodeModal;
