import { Box, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStyles from '@hooks/useStyles';
import { BuyGradientDark } from '@styles/shared/SButtons';
import CardWrapper from '@components/shared/CardWrapper';
import {
  TimeCell,
  WalletCell,
  confirmationCell,
} from '@components/tables-columns/TableCell';
import { TEthInvestment } from '@typescript/models/EthInvestment.model';

interface Props {
  ethInvestments: TEthInvestment[];
  actionSeeMore: () => void;
  count: number;
  isLoading: boolean;
}
function EthInvestmentCards({
  ethInvestments,
  actionSeeMore,
  count,
  isLoading,
}: Props) {
  const navigate = useNavigate();

  const { border1pxSecondaryLight } = useStyles();

  return (
    <Box sx={{ display: 'grid', gap: '0.7rem', pb: '74px' }}>
      {ethInvestments.map((transaction) => (
        <CardWrapper isFetching={isLoading} key={transaction.id}>
          <Box
            key={transaction.id}
            sx={{
              width: '100%',
              borderRadius: '6px',
              border: border1pxSecondaryLight,
              backgroundColor: 'secondary.main',
              mb: '1rem',
            }}
          >
            <Box
              sx={{
                borderBottom: border1pxSecondaryLight,
                borderRadius: '4px 4px 0 0',
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={transaction.ethProject.project.image}
                  style={{ borderRadius: '6px', width: 48, height: 48 }}
                  alt=""
                />
                <Typography
                  sx={{
                    pl: 1,
                    fontWeight: 400,
                    fontSize: '17px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    ':hover': {
                      textDecoration: 'none',
                    },
                  }}
                  onClick={() =>
                    navigate(`/projects/${transaction.ethProject.project.slug}`)
                  }
                >
                  {transaction.ethProject.project.name}
                </Typography>
              </Box>

              <Box>
                <Typography mt={1}>
                  <TimeCell
                    hash={transaction.hash}
                    isEthProject
                    createdAt={transaction.createdAt}
                  />
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
              }}
            >
              <Box
                sx={{
                  p: 3,
                  borderBottom: border1pxSecondaryLight,
                  borderRight: border1pxSecondaryLight,
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
                >
                  Investment Amount
                </Typography>
                <Typography mt={1}>{transaction.investment}</Typography>
              </Box>

              <Box
                sx={{
                  p: 3,
                  borderRight: border1pxSecondaryLight,
                  borderBottom: border1pxSecondaryLight,
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
                >
                  From
                </Typography>
                <WalletCell wallet={transaction.from.ethAddress} />
              </Box>
              <Box
                sx={{
                  p: 3,
                  borderRight: border1pxSecondaryLight,
                  borderBottom: border1pxSecondaryLight,
                }}
              >
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
                >
                  To
                </Typography>
                <WalletCell wallet={transaction.to} />
              </Box>
            </Box>
            <Box
              sx={{
                p: 3,
                br: border1pxSecondaryLight,
              }}
            >
              <Typography
                sx={{ fontSize: '14px', fontWeight: 500, opacity: 0.5 }}
              >
                Confirmed
              </Typography>
              {confirmationCell(transaction.confirmed).renderCell}
            </Box>
          </Box>
        </CardWrapper>
      ))}
      {isLoading ? (
        <Box
          sx={{
            maxWidth: { xs: '375px', md: 'unset' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            maxWidth: { xs: '375px', md: 'unset' },
            display: count === ethInvestments.length ? 'none' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <BuyGradientDark onClick={() => actionSeeMore()}>
            See More
          </BuyGradientDark>
        </Box>
      )}
    </Box>
  );
}
export default EthInvestmentCards;
