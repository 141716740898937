import { useMoralis } from 'react-moralis';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import Default from '@pages/Default';
import { INACTIVE_ERROR_MESSAGE } from '@constants/CDefaultMessages';

interface Props {
  children: JSX.Element;
}
function MoralisInitialization({ children }: Props) {
  const { isInitializing, isInitialized, user } = useMoralis();

  if (isInitializing) {
    return <IsLoadingCom />;
  }
  if (!isInitializing && !isInitialized) {
    return <Default text={INACTIVE_ERROR_MESSAGE} />;
  }
  if (!user) {
    localStorage.clear();
  }
  return children;
}

export default MoralisInitialization;
