import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TListing } from '@typescript/models/Listing.model';
import { ListingAction } from '@constants/CDataActions';
import useStyles from '@hooks/useStyles';
import { BuyGradientDark } from '@styles/shared/SButtons';
import {
  SocialCell,
  ListingStatusCell,
  askingPriceCell,
  currentTokenPriceCell,
  expireInCell,
  negotiableCell,
  offeredPriceCell,
  tokenAmountCell,
  totalCostCell,
  totalOfferCostCell,
} from '@components/tables-columns/TableCell';
import { CurrentUser } from '@typescript/models/User.model';
import { useGetActiveListingsQuery } from '@store/api/listingApi';
import { getListingByTypeZod } from '@typescript/dtos/listing/getListings.dto';
import { TListingTab } from '@typescript/TTabs';
import { useState } from 'react';
import { getTradeListingActions } from '@components/tables-columns/ListingsColumns';
import CardWrapper from '@components/shared/CardWrapper';
import useActionOrConnect from '@hooks/useActionOrConnect';
import TableCellStyled from './TableCellStyled';

interface Props {
  handleListingActions: (
    listing: TListing,
    listingAction: ListingAction,
  ) => void;
  tab: TListingTab;
  currentUser?: CurrentUser;
}

function ExploreTableResponsive({
  handleListingActions,
  currentUser,
  tab,
}: Props) {
  const handleActionOrConnect = useActionOrConnect();

  const [pageSize, setPageSize] = useState(20);
  const {
    data: { count, resource: listings } = { count: 0, resource: [] },
    isFetching,
  } = useGetActiveListingsQuery(
    getListingByTypeZod.parse({
      type: tab.index,
      page: 1,
      pageSize,
    }),
  );
  const navigate = useNavigate();

  const { palette } = useTheme();

  const { border1pxSecondaryLight } = useStyles();

  return (
    <Box mt={1}>
      <Box
        sx={{
          display: 'grid',
          gap: '0.5rem',
          maxWidth: { xs: '375', md: 'unset' },
          marginInline: 'auto',
          mb: '1rem',
        }}
      >
        {listings.map((listing) => (
          <CardWrapper isFetching={isFetching} key={listing.id}>
            <Box
              sx={{
                width: '100%',
                borderRadius: '6px',
                border: border1pxSecondaryLight,
                backgroundColor: palette.background.default,
              }}
            >
              <Box
                sx={{
                  borderBottom: border1pxSecondaryLight,
                  borderRadius: '8px 8px 0 0',
                  p: '6px',
                  paddingRight: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingLeft: '6px',
                  }}
                >
                  <Box
                    sx={{
                      display: { xs: 'none', sm: 'flex', marginRight: 8 },
                    }}
                  >
                    <img
                      src={listing.project.image}
                      style={{
                        borderRadius: '6px',
                        width: 30,
                        height: 30,
                      }}
                      alt="Project icon"
                    />
                  </Box>

                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '14px',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      ':hover': {
                        textDecoration: 'none',
                      },
                    }}
                    onClick={() =>
                      navigate(`/projects/${listing.project.slug}`)
                    }
                  >
                    {listing.project.name}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    gap: '0.5rem',
                    minWidth: '216px',
                  }}
                >
                  {getTradeListingActions(
                    listing,
                    handleListingActions,
                    handleActionOrConnect,
                    currentUser,
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  display: { xs: 'grid', md: 'flex' },
                  gridTemplateColumns: { xs: '1fr 1fr 1fr' },
                }}
              >
                <TableCellStyled
                  title="Current Token Price"
                  sx={{
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  }}
                >
                  {
                    currentTokenPriceCell(listing.project.currentPrice)
                      .renderCell
                  }
                </TableCellStyled>

                <TableCellStyled
                  title="Asking Price"
                  sx={{
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  }}
                >
                  {askingPriceCell(listing).renderCell}
                </TableCellStyled>

                <TableCellStyled
                  title="Token Amount"
                  sx={{
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  }}
                >
                  {tokenAmountCell(listing.amount).renderCell}
                </TableCellStyled>

                <TableCellStyled
                  title="Total Cost"
                  sx={{
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  }}
                >
                  {totalCostCell(listing.price).renderCell}
                </TableCellStyled>

                <TableCellStyled
                  title="Offered Price"
                  sx={{
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  }}
                >
                  {offeredPriceCell(listing).renderCell}
                </TableCellStyled>

                <TableCellStyled
                  title="Total Offer Cost"
                  sx={{
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  }}
                >
                  {totalOfferCostCell(listing).renderCell}
                </TableCellStyled>

                <TableCellStyled
                  title="Negotiable"
                  sx={{
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  }}
                >
                  {negotiableCell(listing).renderCell}
                </TableCellStyled>

                <TableCellStyled
                  title="Expire In"
                  sx={{
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  }}
                >
                  {expireInCell(listing).renderCell}
                </TableCellStyled>

                <TableCellStyled
                  title="Status"
                  sx={{
                    borderBottom: { xs: border1pxSecondaryLight, md: 'none' },
                  }}
                >
                  <ListingStatusCell listing={listing} />
                </TableCellStyled>

                <TableCellStyled title="Social">
                  <SocialCell listing={listing} />
                </TableCellStyled>
              </Box>
            </Box>
          </CardWrapper>
        ))}
      </Box>
      {isFetching ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: count === listings.length ? 'none' : 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <BuyGradientDark
              onClick={() => {
                setPageSize(pageSize + 10);
              }}
            >
              See More
            </BuyGradientDark>
          </Box>
        </Box>
      )}
    </Box>
  );
}
export default ExploreTableResponsive;
