import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SeeMoreButton } from '@styles/portfolio/SPortfolio';
import useStyles from '@hooks/useStyles';
import { Svgs } from '@utils/svgs';
import DataWrapper from '@components/shared/DataWrapper';
import { useGetMyTradeTxByTypeQuery } from '@store/api/transactionApi';
import { getTradeTxByTypeZod } from '@typescript/dtos/transaction/getTransaction.dto';
import {
  projectCell,
  tokenAmountCell,
  totalCostCell,
  tradeTypeCell,
  WalletCell,
} from '@components/tables-columns/TableCell';

function DashboardTransactionsResponsive() {
  const {
    data: { resource: listings } = { resource: [] },
    isLoading: isLoadingListings,
  } = useGetMyTradeTxByTypeQuery(
    getTradeTxByTypeZod.parse({
      page: 1,
      pageSize: 5,
    }),
  );

  const navigate = useNavigate();

  const { border1pxSecondaryLight } = useStyles();

  return (
    <DataWrapper
      isLoading={isLoadingListings}
      data={listings}
      defaultOptions={{
        icon: Svgs.IconTransactions,
        text: 'There are no transactions to display at this moment',
      }}
      isLoadingOptions={{ dimension: 'medium' }}
    >
      <Box sx={{ display: 'grid', gap: '0.7rem', pb: '1rem' }}>
        {listings.slice(0, 4).map((item) => (
          <Box
            key={item.id}
            sx={{
              borderRadius: '6px',
              border: border1pxSecondaryLight,
            }}
          >
            <Box
              sx={{
                borderBottom: border1pxSecondaryLight,
                borderRadius: '4px 4px 0 0',
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {projectCell(item.project).renderCell}
            </Box>
            <Box
              sx={{
                display: { md: 'flex' },
                flexWrap: 'wrap',
                flexDirection: { ms: 'column' },
              }}
            >
              <Box sx={{ display: 'flex', flex: 6 }}>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight },
                    borderRight: { md: border1pxSecondaryLight },
                    flex: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: 'text.shadedWhite',
                      mb: 1,
                    }}
                  >
                    Event
                  </Typography>
                  {tradeTypeCell(item.tradeType).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight },
                    borderRight: { md: border1pxSecondaryLight },
                    flex: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: 'text.shadedWhite',
                      mb: 1,
                    }}
                  >
                    Total Cost
                  </Typography>
                  {totalCostCell(item.value).renderCell}
                </Box>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: border1pxSecondaryLight },
                    borderRight: { md: border1pxSecondaryLight },
                    flex: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: 'text.shadedWhite',
                      mb: 1,
                    }}
                  >
                    Token Amount
                  </Typography>
                  {tokenAmountCell(item.tokenAmount).renderCell}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flex: 6 }}>
                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: 'none', md: border1pxSecondaryLight },
                    borderRight: { xs: 'none', md: border1pxSecondaryLight },
                    flex: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: 'text.shadedWhite',
                      mb: 1,
                    }}
                  >
                    From
                  </Typography>
                  <WalletCell wallet={item.from.ethAddress} />
                </Box>

                <Box
                  sx={{
                    p: 2,
                    borderBottom: { xs: 'none', md: border1pxSecondaryLight },
                    borderRight: { xs: 'none', md: border1pxSecondaryLight },
                    flex: 4.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: 'text.shadedWhite',
                      mb: 1,
                    }}
                  >
                    To
                  </Typography>
                  <WalletCell wallet={item.to.ethAddress} />
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: { xs: 0, lg: 2 },
          }}
        >
          <SeeMoreButton
            sx={{
              borderTop: border1pxSecondaryLight,
              borderRadius: '6px !important',
              width: '130px !important',
              fontSize: '13px',
            }}
            onClick={() => navigate('/activity-stream')}
            fullWidth
          >
            See More
          </SeeMoreButton>
        </Box>
      </Box>
    </DataWrapper>
  );
}
export default DashboardTransactionsResponsive;
