import { TListing } from '@typescript/models/Listing.model';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import { DataGridCustomStyles } from '@styles/shared/SDataGrid';
import { memo, useMemo } from 'react';
import DataGridWrapper from '@components/shared/DataGridWrapper';

interface Props {
  content: TListing[];
  customToolbar: () => JSX.Element;
  pageSize: number;
  columns: GridColumns<TListing>;
  count: number;
  currentPage: number;
  setCurrentPage: (data: number) => void;
  setPageSize: (data: number) => void;
  isFetching: boolean;
}

function ExploreTable({
  content,
  customToolbar,
  columns,
  currentPage,
  isFetching,
  pageSize,
  setCurrentPage,
  setPageSize,
  count,
}: Props) {
  const theme = useTheme();

  const DataGridRemovedScroll = useMemo(() => {
    return {
      ...DataGridCustomStyles,
      '& .MuiDataGrid-virtualScroller': {
        maxHeight: 'calc(100vh - 340px)',
        overflow: 'auto!important',
        paddingBottom: '2px',
        [theme.breakpoints.up('xl')]: {
          maxHeight: 'calc(100vh - 340px)',
          overflowX: 'hidden !important',
          overflowY: 'auto !important',
        },
      },
    };
  }, [theme.breakpoints]);

  return (
    <DataGridWrapper isFetching={isFetching}>
      <DataGrid
        sx={DataGridRemovedScroll}
        rows={content}
        columns={columns}
        disableSelectionOnClick
        autoHeight
        initialState={{
          pagination: { pageSize, page: currentPage },
        }}
        paginationMode="server"
        rowCount={count}
        onPageChange={(newPage) => setCurrentPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        disableColumnSelector
        disableDensitySelector
        components={{ Toolbar: customToolbar }}
      />
    </DataGridWrapper>
  );
}

export default memo(ExploreTable);
