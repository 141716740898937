import { Box } from '@mui/material';
import { TActivityTab } from '@typescript/TTabs';
import { ActivityStreamContentType } from '@constants/CContentType';
import SoldTableResponsive from './SoldTableResponsive';
import BoughtTableResponsive from './BoughtTableResponsive';

interface Props {
  activeTab: TActivityTab;
}

function ActivityTablesResponsive({ activeTab }: Props) {
  const tableContent = () => {
    switch (activeTab.index) {
      case ActivityStreamContentType.SOLD: {
        return <SoldTableResponsive />;
      }
      default: {
        return <BoughtTableResponsive />;
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gap: '0.5rem',
        width: '100%',
        maxWidth: { xs: '450px', md: '711px', lg: 'unset' },
        marginInline: 'auto',
        mt: 1,
        height: '100%',
      }}
    >
      {tableContent()}
    </Box>
  );
}
export default ActivityTablesResponsive;
