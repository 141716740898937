import DataWrapper from '@components/shared/DataWrapper';
import { NO_SOLD_MS } from '@constants/CDefaultMessages';
import { Box, CircularProgress } from '@mui/material';
import { useLazyGetMyTradeTxByTypeQuery } from '@store/api/transactionApi';
import {
  GetTradeTxType,
  TTransaction,
} from '@typescript/models/TradeTransaction.model';
import { useEffect, useRef, useState } from 'react';
import ResponsiveListingCard from './ResponsiveListingCard';

function SoldTableResponsive() {
  const [transactions, setTransactions] = useState<TTransaction[]>([]);
  const pageSize = 3;
  const [page, setPage] = useState<number>(1);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const observer = useRef<IntersectionObserver | null>(null);
  const bottomBoundaryRef = useRef<HTMLDivElement>(null);
  const [newTransactions] = useLazyGetMyTradeTxByTypeQuery();
  const [count, setCount] = useState<number>(999999);

  const loadMore = async () => {
    setIsFetching(true);
    const newBatch = await newTransactions({
      page,
      pageSize,
      type: GetTradeTxType.SOLD,
    }).unwrap();

    setCount(newBatch.count);
    if (newBatch.resource.length !== 0) {
      setTransactions((oldValues) => [...oldValues, ...newBatch.resource]);
      setPage(page + 1);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && transactions.length < count) {
          loadMore();
        }
      },
      { threshold: 0.5 },
    );

    const bottomRef = bottomBoundaryRef.current;

    if (bottomRef) {
      observer.current.observe(bottomRef);
    }

    return () => {
      if (observer.current && bottomRef) {
        observer.current.unobserve(bottomRef);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);

  return (
    <DataWrapper
      isFetching={isFetching}
      isLoading={false}
      data={transactions}
      defaultOptions={{
        fullHeight: true,
        text: NO_SOLD_MS,
      }}
      isLoadingOptions={{
        dimension: 'contained',
      }}
    >
      <Box>
        <Box sx={{ display: 'grid', rowGap: '1rem' }}>
          {transactions.map((tx) => (
            <ResponsiveListingCard key={tx.id} transaction={tx} />
          ))}
        </Box>
        <div ref={bottomBoundaryRef} />
        {isFetching && (
          <Box textAlign="center" mt="1rem" minHeight={21}>
            <CircularProgress size="1rem" />
          </Box>
        )}
      </Box>
    </DataWrapper>
  );
}

export default SoldTableResponsive;
