import { Box, Button, CircularProgress } from '@mui/material';
import useExcelProjectData from '@hooks/useExcelProjectData';
import useExcelDistribution from '@hooks/useExcelDistribution';
import useTokenDistribution from '@hooks/useTokenDistribution';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import { useNavigate } from 'react-router-dom';

import { useLazyGetActiveAllocationQuery } from '@store/api/allocationApi';
import { useLazyGetProjectsQuery } from '@store/api/projectApi';
import { useEffect, useState } from 'react';
import { toRem } from '@utils/functions';
import useProjectExcelRecalculations from '@hooks/useProjectExcelRecalculations';

function AccountAdmin() {
  const [allProjects] = useLazyGetProjectsQuery();

  const navigate = useNavigate();

  const [allAllocations] = useLazyGetActiveAllocationQuery();

  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const [distributionsExist, setDistributionsExist] = useState(false);

  const { tokenDistributions } = useTokenDistribution();

  const {
    handleUploadExcel,
    handleExportProjects,
    isLoading: projectExcelLoading,
  } = useExcelProjectData();

  const { isLoading, recalculateExcelProject } =
    useProjectExcelRecalculations();

  const handleRecalculateExcelProject = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const excelFile = event?.target?.files?.[0];
    if (!excelFile) {
      throw Error('No excel file found!');
    }
    await recalculateExcelProject(excelFile);
  };

  const {
    handleUploadDistributions,
    isLoading: distributionExcelLoading,
    handleExportDistributions,
  } = useExcelDistribution();

  const handleProjectExcelFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const excelFile = event?.target?.files?.[0];
    if (excelFile) {
      await handleUploadExcel(excelFile);
    }
  };

  const handleDistributionExcelFile = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const excelFile = event?.target?.files?.[0];
    if (excelFile) {
      handleUploadDistributions(excelFile);
    }
  };

  const handleExcelExportProjects = async () => {
    setExportLoading(true);
    const allocations = await allAllocations({
      page: 1,
      // TODO: migrated on backend
      pageSize: 3000,
    }).unwrap();
    if (!allocations) {
      throw Error('activeAllocations no provided');
    }
    const projects = await allProjects({
      page: 1,
      pageSize: 200,
    }).unwrap();
    handleExportProjects(allocations, projects.resource);
    setExportLoading(false);
  };

  const handleExcelExportDistributions = async () => {
    const distributions = await tokenDistributions({
      page: 1,
      pageSize: 200,
    }).unwrap();
    const projects = await allProjects({
      page: 1,
      pageSize: 200,
    }).unwrap();
    handleExportDistributions(distributions, projects.resource);
  };

  const handleCreateNewProject = () => {
    navigate('/project-create');
  };

  useEffect(() => {
    const checkDistributionsExists = async () => {
      const distributions = await tokenDistributions({
        page: 1,
        pageSize: 3,
      }).unwrap();

      setDistributionsExist(distributions.length > 0);
    };
    checkDistributionsExists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (projectExcelLoading || distributionExcelLoading) {
    return <IsLoadingCom />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        justifyContent: 'center',
        flexWrap: 'wrap',
        py: 2,
      }}
    >
      <Button variant="contained" onClick={handleCreateNewProject}>
        Create new project
      </Button>
      <label htmlFor="excel-project-file">
        <input
          id="excel-project-file"
          hidden
          multiple
          type="file"
          onChange={(event) => handleProjectExcelFile(event)}
        />
        <Button variant="contained" component="span">
          Upload excel project
        </Button>
      </label>
      <label htmlFor="excel-distribution-file">
        <input
          id="excel-distribution-file"
          hidden
          multiple
          type="file"
          onChange={(event) => handleDistributionExcelFile(event)}
        />
        <Button variant="contained" component="span">
          Upload excel distribution
        </Button>
      </label>
      <label htmlFor="excel-project-recalculations-file">
        <input
          id="excel-project-recalculations-file"
          hidden
          multiple
          type="file"
          onChange={(event) => handleRecalculateExcelProject(event)}
        />
        <Button
          variant="contained"
          component="span"
          disabled={isLoading}
          startIcon={
            isLoading && (
              <CircularProgress size={20} sx={{ marginRight: toRem(8) }} />
            )
          }
        >
          Upload project to recalculate
        </Button>
      </label>
      <Button
        variant="contained"
        component="span"
        disabled={exportLoading}
        onClick={handleExcelExportProjects}
      >
        {exportLoading && (
          <CircularProgress size={20} sx={{ marginRight: toRem(8) }} />
        )}
        Export Projects
      </Button>
      <Button
        variant="contained"
        component="span"
        disabled={!distributionsExist}
        onClick={handleExcelExportDistributions}
      >
        Export Distributions
      </Button>
    </Box>
  );
}

export default AccountAdmin;
