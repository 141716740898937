import { DataGrid } from '@mui/x-data-grid';
import { DataGridCustomStyles } from '@styles/shared/SDataGrid';
import { memo, useState } from 'react';
import DataWrapper from '@components/shared/DataWrapper';
import { useGetMyTradeTxByTypeQuery } from '@store/api/transactionApi';
import { NO_SOLD_MS } from '@constants/CDefaultMessages';
import { GetTradeTxType } from '@typescript/models/TradeTransaction.model';
import { baseTradeTxColumns } from '@components/tables-columns/TradeTxColumns';
import DataGridWrapper from '@components/shared/DataGridWrapper';

function SoldTable() {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(5);

  const { data, isLoading, isFetching } = useGetMyTradeTxByTypeQuery({
    page: page + 1,
    pageSize,
    type: GetTradeTxType.SOLD,
  });

  return (
    <DataWrapper
      isLoading={isLoading}
      data={data?.resource || []}
      defaultOptions={{
        fullHeight: true,
        text: NO_SOLD_MS,
      }}
      isLoadingOptions={{
        dimension: 'contained',
        sx: {
          height: '100%',
        },
      }}
    >
      <DataGridWrapper isFetching={isFetching}>
        <DataGrid
          sx={DataGridCustomStyles}
          rows={data?.resource || []}
          columns={baseTradeTxColumns}
          autoHeight
          disableSelectionOnClick
          pageSize={pageSize}
          paginationMode="server"
          rowCount={data?.count || 0}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={(newPage) => setPage(newPage)}
          rowsPerPageOptions={[5, 10, 20, 50]}
          disableColumnSelector
          disableDensitySelector
          disableColumnFilter
        />
      </DataGridWrapper>
    </DataWrapper>
  );
}

export default memo(SoldTable);
