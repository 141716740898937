import { Box, Typography, useTheme, Button } from '@mui/material';
import { toRem } from '@utils/functions';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { setSocialStep } from '@store/slices/connectSlice';
import { store, useAppDispatch } from '@store/store';
import useActionOrConnect from '@hooks/useActionOrConnect';
import { SOCIAL_STEPS } from '@typescript/MultistepSocial.enum';

function DashboardSocialReminder() {
  const actionOrConnect = useActionOrConnect();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isSocialSkipped = Boolean(localStorage.getItem('socialSkipped'));

  const handleDiscardNotification = () => {
    dispatch(setSocialStep(SOCIAL_STEPS.SECOND_STEP));
    actionOrConnect();
  };

  const handleSocialPopup = () => {
    actionOrConnect();
  };

  if (isSocialSkipped || store.getState().connect.hasSocials) {
    return null;
  }

  return (
    <Box
      sx={{
        minHeight: {
          xs: isSocialSkipped ? '0px' : '128px',
          md: isSocialSkipped ? '0px' : '66px',
        },
        transition: `min-height ${theme.transitions.duration.complex}ms ${theme.transitions.easing.easeInOut}`,
      }}
    >
      <Box
        sx={{
          padding: toRem(12),
          backgroundColor: 'error.main',
          borderRadius: toRem(8),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'ceter',
          fontSize: toRem(16),

          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: toRem(14),
          },
        }}
        mt={3}
        mx={2}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.background.default,
          }}
        >
          <Typography>
            You do not have all the social details. You are missing out on
            connection opportunities.
          </Typography>
        </Box>

        <Box
          sx={{
            [theme.breakpoints.down('md')]: {
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
            },
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              flexShrink: 0,
              alignItems: 'center',
            },
          }}
        >
          <Button
            sx={{
              backgroundColor: 'transparent',
              color: theme.palette.background.default,
              flexShrink: '0',
              '&:hover': {
                color: theme.palette.text.primary,
              },
            }}
            onClick={handleDiscardNotification}
            size="small"
            variant="outlined"
          >
            I don't want to update my social
            <CloseIcon fontSize="small" />
          </Button>

          <Button
            LinkComponent="span"
            onClick={handleSocialPopup}
            sx={{ marginLeft: { xs: '0', md: toRem(8) } }}
            size="small"
            variant="outlined"
            href="/my-listings/inactive"
          >
            Update My Social details
            <ArrowForwardIcon sx={{ marginLeft: toRem(4) }} fontSize="small" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardSocialReminder;
