import { DataGrid } from '@mui/x-data-grid';
import { TAllocation } from '@typescript/models/Allocation.model';
import { useNavigate } from 'react-router-dom';
import { DataGridSeeMoreStyles } from '@styles/shared/SDataGrid';
import { SeeMoreButton } from '@styles/portfolio/SPortfolio';
import { Svgs } from '@utils/svgs';
import DataWrapper from '@components/shared/DataWrapper';
import { memo, useMemo } from 'react';
import { getAllocationsColumnsWithActions } from '@components/tables-columns/AllocationsColumns';
import DataGridWrapper from '@components/shared/DataGridWrapper';

interface Props {
  myAllocations?: TAllocation[];
  handleSellNowBtn: (allocation: TAllocation) => void;
  isLoading: boolean;
  isFetching: boolean;
}

function DashboardPortfolioTable({
  myAllocations,
  handleSellNowBtn,
  isLoading,
  isFetching,
}: Props) {
  const navigate = useNavigate();

  const columns = useMemo(
    () => getAllocationsColumnsWithActions(handleSellNowBtn),
    [handleSellNowBtn],
  );

  return (
    <DataWrapper
      isLoading={isLoading}
      data={myAllocations || []}
      defaultOptions={{
        icon: Svgs.EmptyProjects,
        text: 'There are no projects to display at this moment',
      }}
      isLoadingOptions={{ dimension: 'medium' }}
    >
      <DataGridWrapper isFetching={isFetching}>
        <DataGrid
          sx={DataGridSeeMoreStyles}
          disableSelectionOnClick
          rows={myAllocations || []}
          columns={columns}
          pageSize={4}
          getRowId={(row) => row.id}
          autoHeight
          hideFooter
          disableColumnMenu
        />
      </DataGridWrapper>
      <SeeMoreButton
        onClick={() => navigate('/portfolio')}
        fullWidth
        disableRipple
      >
        See More
      </SeeMoreButton>
    </DataWrapper>
  );
}

export default memo(DashboardPortfolioTable);
