import {
  Box,
  CircularProgress,
  InputAdornment,
  MenuItem,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { TGlobalSearchResult } from '@typescript/TSearch';
import { Svgs } from '@utils/svgs';
import {
  SearchBar,
  SearchBox,
  SearchSelect,
} from '@styles/header/SHeaderStyles';
import SearchBarModal from '@components/header/search/SearchBarModal';
import { GlobalSearchOptions } from '@constants/CSearch';
import { toRem } from '@utils/functions';
import customColors from '@theme/customColors';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useDebounce } from 'react-use';
import { useLazySearchProjectsQuery } from '@store/api/projectApi';
import { nameZod } from '@typescript/dtos/shared/id.dto';
import { useLazySearchListingsQuery } from '@store/api/listingApi';
import { useLazySearchOffersQuery } from '@store/api/offerApi';

function SearchCom() {
  const [triggerSearchByProject] = useLazySearchProjectsQuery();

  const [triggerSearchByListing] = useLazySearchListingsQuery();

  const [triggerSearchByOffers] = useLazySearchOffersQuery();

  const [searchTerm, setSearchTerm] = useState('');

  const [requestName, setRequestName] = useState('');

  const [searchResults, setSearchResults] = useState<TGlobalSearchResult>({
    projects: undefined,
    listings: undefined,
    offers: undefined,
  });

  const resetSearchResults = () => {
    setSearchResults({
      offers: undefined,
      listings: undefined,
      projects: undefined,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [, cancel] = useDebounce(
    () => {
      if (searchTerm !== '') {
        resetSearchResults();
        setRequestName(searchTerm);
      }
    },
    1000,
    [searchTerm],
  );
  const [isLoading, setIsLoading] = useState(false);

  const [openResults, setOpenResults] = useState<boolean>(false);

  const [openSelectDropdown, setOpenSelectDropdown] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const theme = useTheme();

  const [searchBy, setSearchBy] = useState<GlobalSearchOptions>(
    GlobalSearchOptions.PROJECTS,
  );

  const handleSelectSearchBy = (searchProp: GlobalSearchOptions) => {
    setSearchBy(searchProp);
  };

  const handleSearchTyping = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const searchText = event.target.value;
    if (searchText !== '') {
      setIsLoading(true);
      setSearchTerm(searchText);
      setOpenResults(true);
      setAnchorEl(event.target);
    } else {
      setOpenResults(false);
      resetSearchResults();
    }
  };

  const SelectMenuProps = {
    sx: {
      padding: '10px',
      top: '20px',
      left: '-30px',
      '.MuiPaper-root': {
        minWidth: '123px !important',
        border: `1px solid ${theme.palette.secondary.light} !important`,
        borderRadius: toRem(8),
        background: 'transparent',
        boxShadow: `0px 12px 14px 0px ${customColors.boxShadow45}`,
      },
      '.MuiList-root': {
        padding: '5px',
        background: customColors.darkBlue70,
        backdropFilter: 'blur(20px)',
        '& .MuiButtonBase-root + .MuiButtonBase-root': {
          marginTop: '5px',
        },
      },
      '.Mui-selected': {
        backgroundColor: `${theme.palette.primary.contrastText} !important`,
        color: `${theme.palette.text.primary} !important`,
      },
      '.MuiButtonBase-root': {
        padding: '5px',
        color: theme.palette.primary.dark,
        borderRadius: toRem(4),
        fontSize: 13,
        ':hover': {
          backgroundColor: theme.palette.primary.contrastText,
        },
      },
    },
  };

  const SelectMenuResponsiveProps = {
    sx: {
      padding: '10px',
      top: '10px',
      left: '0px',
      '.MuiPaper-root': {
        backdropFilter: 'blur(14px)',
        border: `1px solid ${theme.palette.secondary.light}`,
        borderRadius: toRem(8),
        minWidth: '158px !important',
      },
      '.MuiList-root': {
        padding: '0',
      },
      '.Mui-selected': {
        backgroundColor: `${theme.palette.primary.contrastText} !important`,
        color: `${theme.palette.text.primary} !important`,
      },
      '.MuiButtonBase-root': {
        padding: '0.5rem 1rem',
        color: theme.palette.primary.dark,
        ':hover': {
          backgroundColor: theme.palette.primary.contrastText,
        },
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      switch (searchBy) {
        case GlobalSearchOptions.PROJECTS: {
          const response = await triggerSearchByProject(
            nameZod.parse({ name: requestName }),
          ).unwrap();
          setSearchResults({
            offers: searchResults.offers,
            listings: searchResults.listings,
            projects: response,
          });
          break;
        }
        case GlobalSearchOptions.OFFERS: {
          const response = await triggerSearchByOffers(
            nameZod.parse({ name: requestName }),
          ).unwrap();
          setSearchResults({
            offers: response,
            listings: searchResults.listings,
            projects: searchResults.projects,
          });
          break;
        }
        default: {
          const response = await triggerSearchByListing(
            nameZod.parse({ name: requestName }),
          ).unwrap();
          setSearchResults({
            offers: searchResults.offers,
            listings: response,
            projects: searchResults.projects,
          });
          break;
        }
      }

      setIsLoading(false);
    };
    if (requestName !== '') {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestName, setSearchResults]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: { xs: 'none', lg: '1.5rem' },
        alignItems: 'center',
        flexDirection: { xs: 'column', lg: 'row' },
      }}
    >
      <SearchBox
        sx={{
          borderRadius: openResults ? '8px 8px 0 0' : '8px',
          border: `1px solid ${theme.palette.secondary.light}`,
        }}
      >
        <Svgs.Search
          style={{
            color: theme.palette.text.secondary,
            width: toRem(18),
            height: toRem(18),
            marginLeft: toRem(6),
          }}
        />
        <SearchBar
          placeholder="Search By Project Name..."
          onChange={(event) => handleSearchTyping(event)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && <CircularProgress size={20} />}
              </InputAdornment>
            ),
          }}
        />
        <SearchSelect
          onChange={(event) =>
            handleSelectSearchBy(event.target.value as GlobalSearchOptions)
          }
          value={searchBy}
          sx={{
            maxHeight: '36px',
            display: { lg: 'initial', xs: 'none' },
            '& .MuiSelect-select > svg': {
              display: 'none',
            },
          }}
          MenuProps={SelectMenuProps}
          IconComponent={ExpandMoreRoundedIcon}
        >
          <MenuItem disableGutters disableRipple value="Projects">
            <Svgs.Projects
              width={15}
              height={15}
              style={{ marginRight: '7px' }}
            />
            Projects
          </MenuItem>
          <MenuItem disableGutters disableRipple value="Listings">
            <Svgs.MyListings
              width={15}
              height={15}
              style={{ marginRight: '7px' }}
            />
            Listings
          </MenuItem>
          <MenuItem disableGutters disableRipple value="Offers">
            <Svgs.Offers
              width={15}
              height={15}
              style={{ marginRight: '7px' }}
            />
            Offers
          </MenuItem>
        </SearchSelect>
      </SearchBox>
      <SearchSelect
        onChange={(event) =>
          handleSelectSearchBy(event.target.value as GlobalSearchOptions)
        }
        value={searchBy}
        sx={{
          minWidth: { lg: '125px', xs: '158px' },
          padding: '10px',
          maxHeight: 'unset',
          borderRadius: 0,
          display: { lg: 'none', xs: 'block' },
          '.MuiSelect-select': {
            padding: '0 !important',
          },
        }}
        MenuProps={SelectMenuResponsiveProps}
        onClick={() => setOpenSelectDropdown(!openSelectDropdown)}
      >
        <MenuItem disableGutters disableRipple value="Projects">
          Projects
        </MenuItem>
        <MenuItem disableGutters disableRipple value="Listings">
          Listings
        </MenuItem>
        <MenuItem disableGutters disableRipple value="Offers">
          Offers
        </MenuItem>
      </SearchSelect>
      {openResults && searchResults && (
        <SearchBarModal
          anchorEl={anchorEl}
          handleClose={() => {
            setOpenResults(false);
            resetSearchResults();
          }}
          openResults={openResults}
          searchBy={searchBy}
          searchResults={searchResults}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
}
export default SearchCom;
