import { Typography } from '@mui/material';
import { formatProjectNameParams } from '@utils/functions';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function HeaderNavLabel() {
  const location = useLocation();
  const [pageName, setPageName] = useState<string>('');

  useEffect(() => {
    setPageName(() => {
      if (location.pathname === '/') {
        return 'Dashboard';
      }
      if (location.pathname === '/account') {
        return 'Account Settings';
      }
      const firstLetter = location.pathname.charAt(1).toUpperCase();
      const headerTitle = firstLetter + location.pathname.slice(2);

      if (location.pathname.includes('projects/')) {
        const [, page, projectName] = location.pathname.split('/');
        const pageFirstLetter = page.charAt(0).toUpperCase();
        const pageFirstWord = pageFirstLetter + page.slice(2);
        const validProjectName = formatProjectNameParams(projectName);
        return `${pageFirstWord} > ${validProjectName}`;
      }
      return headerTitle;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Typography
      sx={{
        display: { lg: 'none' },
        marginLeft: '1.5rem',
        whiteSpace: 'nowrap',
      }}
    >
      {pageName}
    </Typography>
  );
}

export default HeaderNavLabel;
